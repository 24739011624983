export const Routes = {
  home: "/",
  restaurante: "/shops",
  // shops: (id) => {
  //   return `${id}`;
  // },
  hotel: "/hotel",
  experiencias: "/experiences",
  pqrs: "/pqrs",
  // activities: "/activities",
};
