import React, { useState } from "react";
import { Outlet } from "react-router-dom";

import Sidebar from "./Sidebar";
import Navbar from "pages/Dash/Navbar";
import Loading from "components/Tools/Loading";
import logo from "assets/img/logo.png";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/20/solid";

function Dash(props) {
  const { menusDash } = props;
  let [openSidebar, setOpenSidebar] = useState(true);

  function handleSidebar() {
    setOpenSidebar(!openSidebar);
  }

  let IconDash = openSidebar ? (
    <Bars3Icon className="h-7 w-7  text-white " />
  ) : (
    <XMarkIcon className="h-7 w-7 md:h-8 md:w-8 text-white " />
  );

  return (
    <div id="dash" className="h-full w-full z-10">
      <header className="flex columns-2 from-gray-800 to-gray-900 bg-gradient-to-r h-16 fixed sm:relative w-full z-30">
        <div className="h-full w-1/2 hover:opacity-70 hover:cursor-pointer flex">
          <button className=" px-4 md:px-5" onClick={handleSidebar}>
            {IconDash}
          </button>
          <img
            className="mx-0 md:mx-3 my-auto h-full object-contain"
            src={logo}
            width={140}
            alt="logo"
          />
        </div>
        <Navbar routes={menusDash} />
      </header>
      {menusDash ? (
        <div id="d-right" className=" flex min-h-screen pt-16 sm:pt-0">
          <Sidebar
            {...props}
            routes={menusDash}
            open={openSidebar}
            handleSidebar={handleSidebar}
          />
          <Outlet />
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default Dash;
