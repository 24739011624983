import React, { Fragment } from "react";
import { signal } from "@preact/signals-react";

import proxy from "api/proxy";
import { useWizardStore } from "store/wizardStore";
import { useIdSeq } from "store/idSeq";
import mainStore from "store/mainStore";

const data = signal(null);

import { QrReader } from "react-qr-reader";

const ScannerQr = (rec) => {
  const { updateWizard } = useWizardStore();
  const { seq, increment } = useIdSeq();
  const {
    store: storeRecord,
    updateRecord,
    updateStore,
    setRecord,
    record,
  } = mainStore();

  const getProduct = async () => {
    const _storeRecord = { ...storeRecord };
    let _activeRecord = { ...record };
    const sequence = seq;
    const { data: res } = await proxy.search(
      "product.product",
      [["code", "=", data.value]],
      ["name", "id"],
    );
    const { name, id } = res[0];

    const product = { name: name, id: id };

    let line_ = {
      id: sequence,
      code: "codigos",
      name: res[0].name,
      "product.": product,
    };

    let lineStore_ = {
      id: sequence,
      code: "asdasd",
      name: res[0].name,
    };

    let lines = _activeRecord.lines;
    if (!_storeRecord.lines) {
      _storeRecord.lines = new Map();
      _storeRecord.lines.set("create", new Map());
    }
    let to_create = _storeRecord.lines.get("create");
    to_create.set(sequence, lineStore_);
    lines.set(sequence, { ...line_ });
    increment(sequence + -1);

    updateRecord(_activeRecord);
    updateStore(_storeRecord);
  };

  if (data.value) {
    getProduct();
  }

  return (
    <Fragment>
      <div className="grid grid-cols-2">
        <QrReader
          onResult={(result, error) => {
            if (!!result) {
              data.value = result?.text;
            }

            if (!!error) {
              console.info(error);
            }
          }}
          containerStyle={{ padding: "0px" }}
          videoContainerStyle={{ paddingTop: "0px", height: "400px" }}
        />
        <div>
          <div>
            <span>Nombre Producto</span>
            {data.value && <span>QR Data: {data.value}</span>}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default ScannerQr;
