// src/apps/Melhous/Components/CleanValues.js

const processConfig = (record, { prefixToRemove = "", charToRemove = "" }) => {
  const cleanValue = (value) => {
    return typeof value === "string"
      ? value.replace(new RegExp(charToRemove, "g"), "")
      : value;
  };

  const result = {};

  for (const [key, value] of Object.entries(record)) {
    const cleanKey = key.startsWith(prefixToRemove)
      ? key.substring(prefixToRemove.length)
      : key;

    const keys = cleanKey.split(".");

    let current = result;

    keys.forEach((k, index) => {
      if (index === keys.length - 1) {
        current[k] = cleanValue(value);
      } else {
        if (!current[k]) {
          current[k] = {};
        }
        current = current[k];
      }
    });
  }

  return reorganizeConfig(result);
};

const reorganizeConfig = (config) => {
  const result = {};

  Object.keys(config).forEach((key) => {
    const value = config[key];
    if (typeof value === "string" || typeof value === "number") {
      result[key] = value;
    }
  });

  Object.keys(config).forEach((key) => {
    const value = config[key];
    if (typeof value === "object" && value !== null) {
      if (Array.isArray(value)) {
        result[key] = value.map((item) =>
          typeof item === "object" ? reorganizeConfig(item) : item,
        );
      } else {
        result[key] = reorganizeConfig(value);
      }
    }
  });

  return result;
};

export { processConfig, reorganizeConfig };
