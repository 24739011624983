import React, { Fragment, useEffect } from "react";
import { signal } from "@preact/signals-react";

import PureModal from "components/Modals/PureModal";
import { QrReader } from "react-qr-reader";
import Board from "components/Meta/Board";
import ModelSale from "./Model/ModelSale";
import ModelProduct from "./Model/ModelProduct";
import { QrCodeIcon } from "@heroicons/react/24/solid";
import PanelRight from "components/Panel/PanelRight";
import StdButton from "components/Buttons/StdButton";
import QuickTable from "components/Meta/QuickTable";

const open = signal(false);
const data = signal(null);
const dom = signal([]);
const _ctxView = signal([]);
const view = signal("sale");
const boardView = signal(false);
const statusSection = signal(true);
const ScreenQrSale = ({ config }) => {
  let ctxView = ModelSale.ctxView(config);
  let ctxViewProduct = ModelProduct.ctxView(config);

  _ctxView.value = ctxView;
  _ctxView.value.domain = dom.value;

  const MethodSearch = () => {
    return (
      <div className="grid grid-cols-2 max-w-[640px] gap-x-10 mx-auto text-xl font-medium mb-10">
        <div
          className="bg-blue-presik text-white p-6 flex items-center space-x-3 rounded-md shadow-md hover:scale-95 transition-all duration-150 cursor-pointer"
          onClick={() => (open.value = true)}
        >
          <QrCodeIcon className="w-14" />
          <span>Leer venta</span>
        </div>
        <div
          className="bg-yellow-500 text-blue-presik p-6 flex items-center space-x-3 rounded-md shadow-md hover:scale-95 transition-all duration-150 cursor-pointer"
          // onClick={() => setViewInput("write")}
          onClick={() => (open.value = true)}
        >
          <QrCodeIcon className="w-14" />
          <span>Leer producto</span>
        </div>
      </div>
    );
  };
  const handleQrSearch = (result, error) => {
    if (!!result?.text) {
      data.value = result?.text;
      open.value = false;
      dom.value = ["number", "ilike", data.value];
    }

    // if (!!error) {
    //   console.info(error);
    // }
  };

  const ContentScanQr = () => {
    return (
      <div className="overflow-hidden rounded-md">
        <QrReader
          onResult={handleQrSearch}
          containerStyle={{ padding: "0px" }}
          videoContainerStyle={{ paddingTop: "0px", height: "336px" }}
        />
        {data.value && <p>QR Data: {data.value}</p>}
      </div>
    );
  };

  const CurrentViewBoard = (current) => {
    boardView.value = current;
  };

  return (
    <div>
      <div
        className={`flex space-x-10 ${
          statusSection.value ? "flex-row justify-between" : "flex-col"
        }`}
      >
        <div
          className={
            statusSection.value == true
              ? "md:w-[65vw] xl:w-[70vw] "
              : "md:w-12/12 relative pr-10"
          }
        >
          {boardView.value != "form" && (
            <div className="pt-10">
              <MethodSearch />
            </div>
          )}
          {view.value == "sale" ? (
            <Board ctxView={ctxView} currentView={CurrentViewBoard} />
          ) : (
            <div className="mx-3 my-3 lg:mx-4 w-full">
              <div className="flex col-span-12 md:col-span-8 w-4/5 text-zinc-500">
                <h1 className="truncate text-4xl font-light">PRODUCTOS</h1>
              </div>
              <QuickTable ctxView={ctxViewProduct} />
            </div>
          )}
        </div>
        <div className="w-[22vw]">
          <PanelRight
            position={"right"}
            widgets={["status", "numPeople"]}
            title=""
            bgColor={statusSection.value ? "bg-gray-100" : "bg-blue-presik"}
            status={statusSection.value}
            handleChangeStatus={false}
            displayOpen={true}
            style="bg-gray-100 px-2 custom-transition duration-500 sticky w-full"
          >
            <StdButton
              content="VENTAS"
              size="w-full"
              color={view.value == "create" ? "rose" : "green"}
              onClick={() => {
                view.value = "sale";
              }}
            />

            <StdButton
              content="PRODUCTOS"
              size="w-full"
              onClick={() => (view.value = "products")}
              color={"bluePresik"}
            />
          </PanelRight>
        </div>
      </div>
      <div className=""></div>
      <PureModal
        open={open.value}
        onClose={() => (open.value = false)}
        backdrop={true}
        width="w-[440px]"
        overflow="overflow-hidden"
      >
        {open.value && <ContentScanQr />}
      </PureModal>
    </div>
  );
};

export default ScreenQrSale;
