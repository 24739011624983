import React from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage as FM } from "react-intl";

import CardBasic from "components/Card/TwCardBasic";
import mainStore from "store/mainStore";

function FolioCard({ folio }) {
  const total_guests = folio.guests.size;
  const navigate = useNavigate();

  const { updateStore, updateRecord } = mainStore();

  function onClickCard() {
    const options = { state: folio };
    updateStore({ folio: folio.id });
    updateRecord({ folio: folio.id });
    navigate("folio", options);
  }

  if (!folio) {
    return null;
  }

  function sectionDate(name, folio_date) {
    return (
      <div className="mt-4">
        <p className="mt-6 py-2 text-gray-500">
          <FM id={name} name={name} />
        </p>
        <p className="text-lg font-bold">{folio_date}</p>
      </div>
    );
  }
  return (
    <CardBasic onClick={onClickCard}>
      <div className="flex justify-between">
        <p className="text-2xl text-cyan-500">{folio.room.name}</p>
        <p className="text-2xl text-cyan-500">{folio.pax}</p>
        <p className="pl-16">
          <FM id="app.webcheckin.total_guests" name="total_guests" />{" "}
          {total_guests}
        </p>
      </div>
      <div className="truncate">
        <h1>{folio.product?.template?.name}</h1>
      </div>
      <div className="flex justify-between">
        {sectionDate("app.webcheckin.arrival_date", folio.arrival_date)}
        {sectionDate("app.webcheckin.departure_date", folio.departure_date)}
      </div>
    </CardBasic>
  );
}

export default FolioCard;
