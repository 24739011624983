// Sale model line
import proxy from "api/proxy";
import useChildStore from "store/childStore";
import useMainStore from "store/mainStore";
import funcs from "tools/functions";

const getProduct = (value) => {
  const search = [
    "OR",
    [
      ["template.name", "ilike", `%${value}%`],
      ["template.salable", "=", true],
    ],
    [
      ["code", "ilike", `${value}%`],
      ["template.salable", "=", true],
    ],
  ];
  return search;
};

const getSalePrices = async (record) => {
  if (!record.product) return {};
  const parentRec = useMainStore.getState().record;
  const party = funcs.prepareRecord(parentRec.party);
  const store = useChildStore.getState().store;
  let line = {
    product: store.product,
    quantity: Number(store.quantity),
    sale: {
      party: party,
      invoice_party: party,
      price_list: parentRec?.price_list?.id,
    },
  };

  const unit = record.unit?.id ?? record.product["sale_uom."].id;
  const { data: result } = await proxy.methodInstance({
    model: "sale.line",
    method: "on_change_product",
    instance: line,
    args: [],
    ctx: {
      price_list: parentRec?.price_list?.id,
      uom: unit,
      party: parentRec?.party?.id,
      invoice_party: parentRec?.party?.id,
    },
  });

  let taxes = [];
  if (result?.taxes?.add) {
    for (const item of result.taxes.add) {
      taxes.push(item[1].id);
    }
  }
  line.taxes = taxes;
  line.quantity = store.quantity || 1;
  line.unit_price = record.product.list_price;

  const { data: unit_price_w_tax } = await proxy.methodInstance({
    model: "sale.line",
    method: "on_change_with_unit_price_w_tax",
    instance: line,
    args: [],
    ctx: {
      price_list: parentRec?.price_list?.id,
      uom: unit,
      party: parentRec?.party?.id,
      invoice_party: parentRec?.party?.id,
    },
  });

  return { result: result, price_w_tax: unit_price_w_tax, taxes: taxes };
};

const onChangeProduct = async (record, store) => {
  // const parentRecord = parentRec.record;
  const product = record?.product;

  if (product) {
    let unit = product["sale_uom."];
    let { result, price_w_tax, taxes } = await getSalePrices(record);
    if (product.packing_uom) store.packing_uom = product.packing_uom;
    console.log(record, "record");

    record.base_price = result?.base_price;
    record.unit_price = record.product.list_price;
    record.unit = unit;
    record.unit_price_w_tax = price_w_tax;
    record.amount_w_tax = price_w_tax;
    record.description = result?.description;
    store.description = product.description || product.name;
    // store.unit_price = result.unit_price.toString();
    store.unit_price = record.product.list_price.toString();
    store.base_price = result?.base_price.toString();
    store.unit = unit.id;
    store.unit = unit.id;
    store.taxes = [["add", taxes]];
    console.log(record.product.list_price);
  }
};

const onChangeUnit = async (record, store) => {
  let { unit_price, price_w_tax } = await getSalePrices(record);
  record.unit_price = unit_price;
  record.unit_price_w_tax = price_w_tax;
  store.unit_price = unit_price.toString();
  store.unit_price_w_tax = price_w_tax;
};

const onChangeQty = async (record, store) => {
  let { result, price_w_tax, taxes } = await getSalePrices(record);
  if (record.quantity && record.unit_price_w_tax) {
    const qty = record.quantity;
    record.base_price = result?.base_price.toString();
    record.unit_price = record.product.list_price.toString();
    record.unit_price_w_tax = price_w_tax;
    store.unit_price = record.product.list_price.toString();
    store.base_price = result?.base_price.toString();
    if (record.discount) {
      const { amount, discount } = record;
      const amount_ = amount * qty - (amount * qty * discount) / 100;
      record.amount_w_tax = amount_;
    } else {
      record.amount_w_tax = record.unit_price_w_tax * qty;
    }
  }
};

const onChangeUnitPrice = async (record) => {
  if (record.quantity && record.unit_price) {
    const { quantity, unit_price } = record;
    if (record.discount) {
      // fix me calculation discount
      const amount = unit_price - (unit_price * record.discount) / 100;
      record.unit_price_w_tax = amount;
      record.amount_w_tax = amount * quantity;
    } else {
      const { data: price_w_tax } = await proxy.methodCall({
        model: "sale.sale",
        method: "dash_get_amount_w_tax",
        args: [
          {
            product: record.product["id"],
            list_price: unit_price,
          },
        ],
      });
      record.unit_price_w_tax = price_w_tax;
      record.amount_w_tax = price_w_tax * quantity;
    }
  }
};

const searchUnit = (value, record) => {
  let dom = [["category", "=", record?.unit?.category]];
  if (value) {
    dom.push(["name", "ilike", `%${value}%`]);
  }
  return dom;
};

const onChangeDiscount = (record) => {
  if (record.unit_price_w_tax && record.quantity) {
    const { amount, quantity } = record;
    record.total_amount = amount * quantity;
  }
};

const getView = (config) => {
  Array.prototype.insert = function (index, item) {
    this.splice(index, 0, item);
  };
  let DictCtxView = {
    form_action_add: "modal",
    model: "sale.line",
    row_selectable: false,
    table_action: ["edit", "add", "delete"],
    form_action: ["add", "delete", "edit"],
    target: "sale",
    selectable: true,
    webfields: {
      product: {
        type: "many2one",
        model: "product.product",
        // recSearch: getProduct,
        withChange: onChangeProduct,
        attrs: [
          "id",
          "list_price",
          "name",
          "sale_price_taxed",
          "sale_uom.rec_name",
          "description",
          config?.modules_activated?.includes("sale_packing_unit")
            ? "packing_uom"
            : "id",
        ],
        required: true,
      },
      unit: {
        type: "many2one",
        model: "product.uom",
        recSearch: searchUnit,
        withChange: onChangeUnit,
        readOnly: true,
        attrs: ["id", "name", "category", "factor"],
      },
      quantity: {
        type: "float",
        withChange: onChangeQty,
        // required: true,
        decimalPlaces: 2,
        default: 1,
      },
      base_price: { type: "numeric", readOnly: true },
      unit_price: { type: "numeric", readOnly: true, decimalPlaces: 2 },
      unit_price_w_tax: { type: "numeric", readOnly: true },
      amount_w_tax: { type: "numeric", readOnly: true },
      description: { type: "char" },
      discount_rate: {
        type: "numeric",
        withChange: onChangeDiscount,
        search: true,
        decimalPlaces: 2,
      },
      discount_amount: { type: "numeric", readOnly: true, decimalPlaces: 2 },
    },
    webtree: [
      { name: "product", width: "40%" },
      { name: "unit", width: "10%" },
      { name: "quantity", width: "10%" },
      { name: "base_price" },
      { name: "unit_price", width: "10%" },
      { name: "amount_w_tax", width: "23%" },
    ],
    webform: [
      { name: "product" },
      { name: "unit" },
      { name: "quantity" },
      { name: "unit_price" },
      { name: "unit_price_w_tax" },
      { name: "amount_w_tax" },
      { name: "description" },
    ],
  };

  if (config && config.allow_discount) {
    DictCtxView["webtree"].insert(4, {
      name: "discount_rate",
      width: "4%",
      format: "percent",
    });
    DictCtxView["webform"].insert(4, { name: "discount_rate" });
    DictCtxView["webtree"].insert(5, { name: "discount_amount", width: "4%" });
    DictCtxView["webform"].insert(5, { name: "discount_amount" });
  }
  if (config && config.allow_manual_pricing) {
    DictCtxView["webfields"]["unit_price"] = {
      type: "numeric",
      required: true,
      withChange: onChangeUnitPrice,
      decimalPlaces: 2,
    };
  }
  return DictCtxView;
};

export default { ctxView: getView };
