import React from "react";
import GalleryItem from "components/Tools/GalleryItem";
import ButtomExperience from "../ButtomExperience";
import { XMarkIcon } from "@heroicons/react/20/solid";
import funcs from "tools/functions";

const ContentModalExperience = ({ exp, onClose }) => {
  const ImageExp = ({ data }) => {
    return (
      <figure className="shadow-md cursor-move">
        <img
          src={
            data.image_url ||
            "https://www.recetasnestle.com.co/sites/default/files/inline-images/comida-internacional-cocina-de-la-india.jpg"
          }
          className="md:max-w-full h-56 md:min-h-[50vh] rounded-md max-w-full object-cover shadow-xl "
        />
      </figure>
    );
  };

  return (
    <div className="md:grid grid-cols-2 items-stretch  p-8 gap-x-8 bg-gray-50 rounded-md overflow-hidden relative">
      <XMarkIcon
        className="w-10 z-10 absolute md:right-5 md:top-5 right-3 top-3 bg-red-400  text-red-800 rounded-full p-2 cursor-pointer"
        onClick={onClose}
      />
      <div className="w-full">
        <GalleryItem
          data={exp["images."]}
          renderItemComponent={<ImageExp />}
          numberItem={{ desk: 1, mobile: 1 }}
          mode="default"
          style="grid grid-cols-1 md:grid-cols-1 h-full shadow-md
          "
          indicator={true}
        />
      </div>
      <div className="flex flex-col items-stretch h-full justify-between">
        <div className="flex flex-col space-y-3">
          <h3 className="text-xl font-medium">{exp?.name}</h3>
          <span>${funcs.fmtMoney(exp?.sale_price_w_tax)}</span>
          <span>{exp?.description}</span>
        </div>
        <div className="flex justify-center mt-10">
          <ButtomExperience experience={exp} />
        </div>
      </div>
    </div>
  );
};

export default ContentModalExperience;
