import React from "react";
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import { useWizardStore } from "store/wizardStore";

const ButtomExperience = ({
  experience,
  textColorPrimary,
  bgColorPrimary,
  bgColorSecundary,
}) => {
  const { store, updateWizard } = useWizardStore.getState();

  const handleAddExp = () => {
    const _store = { ...store };
    const lineExp = {
      product: experience.id,
      name: experience.name,
      description: experience.description,
      price_tax: experience.sale_price_w_tax,
      price: experience.list_price,
      quantity: 1,
    };

    updateWizard({
      ..._store,
      experiences: [...(_store.experiences || []), lineExp],
    });
  };

  console.log(store, "este es el store");

  return (
    <div className="md:mb-0 flex justify-center md:justify-end md:bottom-0 right-0">
      <button
        className={`group px-5 pt-2 uppercase rounded-lg shadow-md ${
          textColorPrimary || "text-white"
        } flex space-x-2 ${
          "hover:" + bgColorSecundary
        } hover:text-yellow-500 transition-all duration-300 ${
          bgColorPrimary || "bg-blue-presik"
        }`}
        onClick={handleAddExp}
        // disabled={availableRooms}
      >
        <span className="text-sm">
          {/* <FormattedMessage id="app.booking.btn_reserve_item" />{" "}
           */}
          Agregar experiencia
        </span>
        <PlusCircleIcon
          className={`w-7 group-hover:text-white transition-all duration-300 relative -top-1 ${textColorPrimary}`}
        />
      </button>
    </div>
  );
};

export default ButtomExperience;
