// Sale model line
import dates from "tools/dates";

function formatTime(seconds) {
  const hours = Math.floor(seconds / 1000 / 60 / 60);
  const remainingMinutes = Math.floor(seconds / 1000 / 60) % 60;
  const remainingSeconds = Math.floor((seconds / 1000) % 60);

  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = remainingMinutes.toString().padStart(2, "0");
  const formattedSeconds = remainingSeconds.toString().padStart(2, "0");
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

function getTime(rec) {
  const today = new Date();

  const created_ =
    rec["order_status_time."]?.created ?? rec["create_date"].split(".", 1)[0];
  const created = dates.getTrytonDateTime2Js(created_, true) ?? today;
  const commanded =
    dates.getTrytonDateTime2Js(rec["order_status_time."]?.commanded, true) ??
    today;

  const time = formatTime(commanded.getTime() - created.getTime());
  return time;
}

function getTimeCreated(record) {
  const created =
    record["order_status_time."]?.created ??
    record["create_date"].split(".", 1)[0];
  return created;
}

function onChangeParty(record, store) {
  const address = store.party?.addresses ? store.party.addresses[0] : null;
  store.invoice_address = address;
  store.shipment_address = address;
}

const CtxViewLine = {
  form_action_add: "modal",
  model: "sale.line",
  row_selectable: false,
  table_action: [],
  form_action: [],
  webfields: {
    product: {
      type: "many2one",
      model: "product.product",
      required: true,
    },
    unit: {
      type: "many2one",
      model: "product.uom",
      readOnly: true,
    },
    quantity: {
      type: "numeric",
      required: true,
      default: 1,
    },
    note: { type: "text" },
    unit_price: { type: "numeric", readOnly: true },
    amount: { type: "numeric", readOnly: true },
    amount_w_tax: { type: "numeric", readOnly: true },
  },
  webtree: [
    { name: "product", width: "30%" },
    { name: "quantity", width: "10%" },
    { name: "amount", width: "15%" },
    { name: "amount_w_tax", width: "15%" },
    { name: "note", width: "30%" },
  ],
  webform: [
    { name: "product" },
    { name: "quantity" },
    { name: "amount" },
    { name: "amount_w_tax" },
    // { name: "comment" },
  ],
};

const stateColors = {
  draft: "rose",
  requested: "slate",
  commanded: "lime",
  dispatched: "sky",
  delivered: "amber",
};

const ctxViewSale = {
  row_selectable: false,
  activeSearch: true,
  // filters: getFilters,
  // title: { model: true, field: "number" },
  tags: {
    order_status: stateColors,
  },
  limit: 200,
  model: "sale.sale",
  form_action: ["edit"],
  table_action: ["edit"],
  webfields: {
    number: {
      type: "char",
      readOnly: true,
      depends: ["create_date"],
      searchable: true,
    },
    delivery: { type: "money", readOnly: true },
    party: {
      type: "many2one",
      model: "party.party",
      readOnly: { state: ["quotation"] },
      attrs: ["addresses"],
      withChange: onChangeParty,
      searchable: true,
    },
    source: {
      type: "many2one",
      model: "sale.source",
      readOnly: true,
    },
    consumer: {
      type: "many2one",
      model: "party.consumer",
      required: true,
      searchable: true,
    },
    agent: {
      type: "many2one",
      model: "commission.agent",
      readOnly: true,
      searchable: true,
    },
    position: {
      type: "char",
      readOnly: true,
    },
    payment_method: {
      type: "char",
      readOnly: true,
      translate: true,
    },
    lines: {
      type: "one2many",
      model: "sale.line",
      ctxView: CtxViewLine,
      readOnly: true,
    },
    sale_date: {
      type: "date",
      readOnly: true,
    },
    state: {
      type: "char",
      readOnly: true,
      translate: true,
      default: "draft",
    },
    order_status: {
      type: "char",
      readOnly: true,
      translate: true,
      default: "draft",
      tags: stateColors,
    },
    shop: {
      type: "many2one",
      model: "sale.shop",
      readOnly: true,
      searchable: true,
    },
    delivery_amount: { type: "numeric", readOnly: true },
    total_amount: { type: "numeric", readOnly: true },
    description: { type: "char", readOnly: true },
    kind: { type: "char", readOnly: true, translate: true },
    comment: { type: "text", readOnly: true },
    "order_status_time.created": {
      type: "datetime",
      search: true,
      function: getTimeCreated,
    },
    "order_status_time.commanded": {
      type: "datetime",
      // search: true,
      // function: get_time_commanded,
    },
    elapsed_time: {
      type: "char",
      function: getTime,
    },
  },
  webtree: [
    { name: "number", width: "20%" },
    { name: "sale_date", width: "25%" },
    { name: "shop", width: "25%" },
    { name: "party", width: "25%" },
    { name: "consumer", width: "25%" },
    { name: "agent", width: "25%" },
    {
      name: "order_status",
      width: "30%",
      widget: "badge",
    },
    { name: "delivery_amount", width: "25%" },
    { name: "total_amount", width: "25%" },
    {
      name: "order_status_time.created",
      width: "15",
      formatString: "HH:mm:ss",
    },
    {
      name: "order_status_time.commanded",
      width: "15",
      formatString: "HH:mm:ss",
    },
    { name: "elapsed_time", width: "15" },
  ],
  webform: [
    {
      id: "infoSale",
      grid: [
        { name: "number" },
        { name: "sale_date" },
        { name: "source" },
        { name: "shop" },
        { name: "kind" },
      ],
      size: [1, 5],
      span: "col-span-2",
    },
    { name: "consumer" },
    { name: "party" },
    {
      id: "otherInfo",
      grid: [{ name: "position" }, { name: "payment_method" }],
      size: [1, 2],
      span: "col-span-1",
    },
    { name: "delivery_amount" },
    { name: "lines", component: "modal" },
    { name: "total_amount" },
    { name: "order_status" },
    { name: "comment" },
  ],
};
export default ctxViewSale;
