import PageRestaurant from "./pages/PageRestaurant";
import PageExperience from "./pages/PageExperience";
import PageUser from "./pages/PagePqrs";
import imgBanner from "assets/img/bannerBackgound.jpg";
import imgBannerExpe from "assets/img/banner-experiencias.png";
import imgBannerBooking from "assets/img/banner-booking.png";
import imgBannerRecepcion from "assets/img/banner-tellme.png";
import imgBannerTellme from "assets/img/bannerRecepcion.png";
import PagePqrs from "./pages/PagePqrs";
import PageHotel from "./pages/PageHotel";
import { IconsFood, IconChile, IconsNoGluten } from "./components/ui/Icons";

const PRODUCTS = [
  {
    name: "Huevos benedictinos",
    price: 34,
    category: "guilty pleasure",
    image: "https://magefesa.com.co/wp-content/uploads/2021/04/huevos.jpg",
    description:
      "Con holandesa de ghee avellanado, english mullins de masa madre y bacon casero",
  },
  {
    name: "Huevos fritos en aceite de oliva",
    price: 39,
    category: "guilty pleasure",
    image:
      "https://res.cloudinary.com/dqkvq2tk2/image/upload/v1697581687/67fb3e26429460263c0a3db4cdd87103_damtbq.jpg",
    description:
      "English muffins tostados con mantequilla avellanado, bacon casero con miel picante, queso crema batida y aguacate.",
  },
  {
    name: "Pancakes de chocolate y avellanas",
    price: 39,
    category: "guilty pleasure",
    image:
      "https://res.cloudinary.com/dqkvq2tk2/image/upload/v1697582344/5b7cce5e5c69a4690a1c27b3b532c70e_gvqcei.jpg",
    description: "Arandanos, fresas, moras,fudge salado y  queso crema batida",
  },
  {
    name: "Waffles de torta de zanahoria",
    price: 29,
    category: "guilty pleasure",
    image:
      "https://res.cloudinary.com/dqkvq2tk2/image/upload/v1697582744/f9a1da799e27d9deabc74ed13435465c_ao9it0.jpg",
    description:
      "Queso crema batida, pecanas tostadas, manzana verde y miel de abejas",
  },
  {
    name: "Torrijas de pan brioche",
    price: 37,
    category: "guilty pleasure",
    image:
      "https://res.cloudinary.com/dqkvq2tk2/image/upload/v1697582951/499fb78e532b30d8bd6ad9c49ed60f00_anjktr.jpg",
    description:
      "Pan de masa madre, crema inglesa, frutos rojos locales y pecanas tostadas",
  },
  {
    name: "Tortilla de patatas y cebolla",
    price: 29,
    category: "guilty pleasure",
    image:
      "https://res.cloudinary.com/dqkvq2tk2/image/upload/v1697583099/205280d0aa9f536d743f7efe1eadef28_zrtydb.jpg",
    description:
      "Hecha al momento, salsa brava, alioli de ghee avellanado, pan blanco de masa madre con ajo y tomate rallado",
  },
  {
    name: "Sandwich de bacon casero",
    price: 39,
    category: "guilty pleasure",
    image:
      "https://res.cloudinary.com/dqkvq2tk2/image/upload/v1697583433/c6b84d1d309ebf2c0fa394da0aa2eac8_vl5xng.jpg",
    description:
      "En pan de masa madre, alioli de ghee avellanado, cagollos, toamtes y aguacate, papas criollas salteadas",
  },
  {
    name: "Grilled cheesse sandwich",
    price: 37,
    category: "guilty pleasure",
    image:
      "https://res.cloudinary.com/dqkvq2tk2/image/upload/v1697583615/41634b72c40046c767c71e3127fb93cc_hoev7t.jpg",
    description:
      "En focaccio de masa madre con tomates secos y pesto, papas criollas salteadas",
  },
  {
    name: "Sticky fried chicken",
    price: 45,
    category: "guilty pleasure",
    image:
      "https://res.cloudinary.com/dqkvq2tk2/image/upload/v1697583829/8c7c4bf568bf39c355cb1ddd3e952582_glivzt.jpg",
    description:
      "Con maní, ajonjoli,, jengibre, cebolla larga, cilantro y arroz de sushi",
  },
  {
    name: "Berenjenas crocantes",
    price: 35,
    category: "guilty pleasure",
    image:
      "https://res.cloudinary.com/dqkvq2tk2/image/upload/v1697584120/6b20271f431f3850382b88f2236280cd_eatjx5.jpg",
    description:
      "Con salsa de chiles dulces y jengibre, fideos de arroz, maní, cebolla larga, cilantro y ajonjoli",
  },
  {
    name: "Hamburguesa de grass fed beef",
    price: 41,
    category: "guilty pleasure",
    image:
      "https://res.cloudinary.com/dqkvq2tk2/image/upload/v1697584319/a34aa4b00da7ee21f64b7e4c9acf9c48_mwfib8.jpg",
    description:
      "En brioche de ghee avellanado, alioli de ajo negro y huevo frito y fries",
  },
  {
    name: "Crispy fish hotdogs",
    price: 38,
    category: "guilty pleasure",
    image:
      "https://res.cloudinary.com/dqkvq2tk2/image/upload/v1697584501/e07c49d565a8d1529ca040f3afca33fa_frtap2.jpg",
    description:
      "En pan brioche con salsa verde, chucrut, pepinillos y alioli de ghee avellanado, papas criollas salteadas",
  },
  {
    name: "Cinnamon Rolls",
    price: 34,
    category: "guilty pleasure",
    image:
      "https://res.cloudinary.com/dqkvq2tk2/image/upload/v1697584644/91cd1b0278cc66e098fbc0eaa715c46b_b3cyu6.jpg",
    description: "Recien horneados, helado de vainilla y macadamias tostados",
  },
  {
    name: "Huevos Turcos",
    price: 38,
    category: "Vitality boost",
    image:
      "https://res.cloudinary.com/dqkvq2tk2/image/upload/v1697585177/bd7ed748b55cd6c1a12d8a055235ce0f_fzwckp.jpg",
    description:
      "Con yogurt de cabra, ghee de chiles y ajo, almendra y hierbas",
  },
  {
    name: "Huevos shakshuka",
    price: 34,
    category: "Vitality boost",
    image:
      "https://res.cloudinary.com/dqkvq2tk2/image/upload/v1697585529/43ff5063aa3fdd5627a0a164018e49da_nbw8if.jpg",
    description:
      "Sofrito de piminetos, chiles, cebolla, toomate, ajo y comino, queso feta, cilantro",
  },
  {
    name: "Tostada de masa madres con semillas",
    price: 28,
    category: "Vitality boost",
    image:
      "https://res.cloudinary.com/dqkvq2tk2/image/upload/v1697585680/66e72a54f9d390c3f538d86169f3b899_ves8gb.jpg",
    description:
      "Aguacate, huevos mallet, alioli, ghee de chiles dulces y cebilliín",
  },
  {
    name: "Bowl de acai amazonico",
    price: 35,
    category: "Vitality boost",
    image:
      "https://res.cloudinary.com/dqkvq2tk2/image/upload/v1697585861/5c9483164c66f040e580e0432085a521_cpzecs.jpg",
    description:
      "Tapioca, leche de coco, crumble de mambe, castañas y frutos rojos",
  },
  {
    name: "Bowl de kéfir de cabra",
    price: 31,
    category: "Vitality boost",
    image:
      "https://res.cloudinary.com/dqkvq2tk2/image/upload/v1697586006/d7c821b4ca9324a06c68f6efe3eb777c_jhbysb.jpg",
    description:
      "Granola de coco, trigo malteado y miel, arandanos, martiño, moras y nibs de cocoa",
  },
  {
    name: "Pancakes de calabaza",
    price: 33,
    category: "Vitality boost",
    image:
      "https://res.cloudinary.com/dqkvq2tk2/image/upload/v1697586096/77b0e40ee180f89d43cbccd01317d74f_gwgipf.jpg",
    description:
      "Masa madre, yogurt griego de cabra, mermelada de durazno, marañones y miel de abejas",
  },
  {
    name: "Croquetas de quinoa",
    price: 34,
    category: "Vitality boost",
    image:
      "https://res.cloudinary.com/dqkvq2tk2/image/upload/v1697586299/f88c6d05e3a23faf9e7f1dcb033e55b8_y58nzs.jpg",
    description:
      "Albahaca y queso, huevos mallet, ensalda de verdes, pesto,  harissa y aguacate",
  },
  {
    name: "Summer rolls de tofu",
    price: 32,
    category: "Vitality boost",
    image:
      "https://hips.hearstapps.com/vidthumb/images/crispy-satay-tofu-summer-rolls-644278c32152d.jpg?crop=1xw:1xh;center,top&resize=1200:*",
    description:
      "Fideos, albahaca y jalapeños verdes, salsa de mantequilla de maní, hoisin y limon",
  },
  {
    name: "Summer rolls de langostino",
    price: 36,
    category: "Vitality boost",
    image:
      "https://res.cloudinary.com/dqkvq2tk2/image/upload/v1697646317/f5c0bd21a3e2a21c754620fc85b4d285_c1jox5.jpg",
    description:
      "Fideos, albahaca y jalapeños verdes, salsa de mantequilla de maní, hoisin y limon",
  },
  {
    name: "Crudo de res con berenjenas ahumados",
    price: 43,
    category: "Vitality boost",
    image:
      "https://i.pinimg.com/564x/ee/b8/65/eeb865b3f7840a90b651a49b5d4a00e2.jpg",
    description:
      "Con aceite de hongos, verdes, encolinabo encurtido y pan de aceite de oliva",
  },
  {
    name: "Remolachas rostizadas y puré de berenjena ahumado",
    price: 36,
    category: "Vitality boost",
    image:
      "https://i.pinimg.com/564x/25/90/ec/2590ec8a23aa01fbfcc2f72b807ab6c3.jpg",
    description:
      "Con aceite de hongos, verdes, colinabo encurtido, y pan de aceite de oliva",
  },
  {
    name: "Sandwich integral de pastrami de pollo",
    price: 36,
    category: "Vitality boost",
    image:
      "https://i.pinimg.com/564x/59/1f/ba/591fbadffbf97ff16df627b6e6beabfb.jpg",
    description:
      "Tahini, cagollos frescos, cebollas caramelizadas, y queso feta, papas criollas salteadas",
  },
  {
    name: "Ensalda de zanhorias asadas",
    price: 35,
    category: "Vitality boost",
    image:
      "https://i.pinimg.com/564x/3c/db/fd/3cdbfd261b3ad3ac6b3183d4cde26b38.jpg",
    description:
      "Hummus, queso feta local, kole, garbanzos crocantes, tzatziki",
  },
  {
    name: "Solomito grassfed",
    price: 64,
    category: "Vitality boost",
    image:
      "https://i.pinimg.com/564x/56/db/f4/56dbf4107153d0d15b21adaec2a8a36c.jpg",
    description:
      "Con salsa bernesa, papas criolllas salteadas y ensalda fresca",
  },
  {
    name: "Pollo rostizado",
    price: 45,
    category: "Vitality boost",
    image:
      "https://i.pinimg.com/564x/55/42/2b/55422b4735a181cefe7e5d758f7700fb.jpg",
    description: "Con salsa bernesa, papas criollas salteadas y ensalada fesca",
  },
  {
    name: "Clásica",
    price: 14,
    category: "Kombucha",
    image:
      "https://images.hola.com/imagenes/belleza/actualidad/2017071997276/te-kombucha-adelgazar/0-471-152/kombucha-t.jpg",
    description: "Café molido, agua y azúcar.",
  },
  {
    name: "Pasifloras",
    price: 14,
    category: "Kombucha",
    image:
      "https://bioma.bio/cdn/shop/files/DIVINE.jpg?v=1682416102&width=1445",
    description: "Café molido, agua y azúcar.",
  },
  {
    name: "Frutos rojos",
    price: 14,
    category: "Kombucha",
    image:
      "https://us.123rf.com/450wm/bondd/bondd1712/bondd171200254/91497236-mimosa-de-amora-vermelha-de-manh%C3%A3-de-natal-com-alecrim-espa%C3%A7o-de-c%C3%B3pia-de-fundo-de-m%C3%A1rmore-branco.jpg?ver=6",
    description: "Café molido, agua y azúcar.",
  },
  {
    name: "Capuccino",
    price: 7,
    category: "Cafe",
    image:
      "https://i0.wp.com/cafe3caminos.com/wp-content/uploads/2023/05/como-preparar-un-capuccino.png?fit=1900%2C600&ssl=1",
    description: "Café molido, agua y azúcar.",
  },
  {
    name: "Espresso",
    price: 4,
    category: "Cafe",
    image:
      "https://s1.abcstatics.com/media/ciencia/2020/01/23/cafe-kZcF--620x349@abc.jpg",
    description: "Café molido, agua y azúcar.",
  },
  {
    name: "Latte",
    price: 7,
    category: "Cafe",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8wdUCQLzVMzJj4dFcdXnBJUmrrSWFBlwtUYAHVjMU7O4kXBB5ZaYXPGUmy1eEVEd3vus&usqp=CAU",
    description: "Café molido, agua y azúcar.",
  },
  {
    name: "Americano",
    price: 4,
    category: "Cafe",
    image:
      "https://apasionados-por-el-cafe.s3.amazonaws.com/wp-content/uploads/2020/06/taza-de-cafe-americano.jpg",
    description: "Café molido, agua y azúcar.",
  },
  {
    name: "Cortado",
    price: 6,
    category: "Cafe",
    image:
      "https://media.istockphoto.com/id/1030882758/es/foto/m%C3%A1quina-y-caf%C3%A9-espresso.jpg?s=612x612&w=0&k=20&c=-YPkDOwJXLM_x0QRn77fsB82x-ree_yfeINZoXWbu4A=",
    description: "Café molido, agua y azúcar.",
  },
  {
    name: "Macchiato",
    price: 6,
    category: "Cafe",
    image:
      "https://cafesolocontigo.com/wp-content/uploads/2020/03/un-cafe-machiatto-y-vaso-de-agua-min.jpg",
    description: "Café molido, agua y azúcar.",
  },
  {
    name: "Mocaccina",
    price: 9,
    category: "Cafe",
    image:
      "https://www.recetasnestlecam.com/sites/default/files/2022-04/leche-mocaccino.jpg",
    description: "Café molido, agua y azúcar.",
  },
  {
    name: "Cold brew",
    price: 6,
    category: "Cafe",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjv1tk7oe2AFn895XiVzASt--xC_eOCkGyKQ&usqp=CAU",
    description: "Café molido, agua y azúcar.",
  },
  {
    name: "Filtrados x taza",
    price: 6,
    category: "Cafe",
    image:
      "https://apasionados-por-el-cafe.s3.amazonaws.com/wp-content/uploads/2020/06/taza-de-cafe-americano.jpg",
    description: "Café molido, agua y azúcar.",
  },
  {
    name: "Coldbrew leche de almendras",
    price: 10,
    category: "Cafe",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZDzUpEvjYVp_qHOSQd3TqBppDMGmcfOtmGQ&usqp=CAU",
    description: "Café molido, agua y azúcar.",
  },
  {
    name: "Afogatto",
    price: 11,
    category: "Cafe",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3kFOhmNO7Kceq6VepvkR8REfbOkCSiAtYeg&usqp=CAU",
    description: "Café molido, agua y azúcar.",
  },
];

const MENU = [
  {
    name: "Hotel",
    description:
      "Descubre cada detalle de nuestro hotel y disfruta de la comodidad de nuestros espacios diseñados para brindarte una experiencia inolvidable.",
    image: imgBannerBooking,
    url: "hotel",
    // component: PageHotel,
  },
  {
    name: "Experiencias",
    description:
      "Experimenta nuestras propuestas y haz que tu estadía sea aún más placentera.",
    image: imgBannerExpe,
    component: PageExperience,
  },
  {
    name: "Restaurante",
    description:
      "Explora con deleite nuestra extensa variedad de platos únicos y deliciosos. ",
    image: imgBanner,
    // component: PageRestaurant,
    url: "shops",
  },
  {
    name: "Cuentanos",
    description: "Por favor, comparte tus quejas o reclamos con nosotros.",
    image: imgBannerTellme,
    component: PagePqrs,
  },
  {
    name: "Tu Reserva",
    description:
      "Descubre y mantén el control de todos los detalles de tu reserva aquí mismo.",
    image: imgBannerRecepcion,
    // component: PageUser,
    url: "https://c1.presik.com/",
  },
];

const TESTIMONIALS = [
  {
    id: 1,
    name: "VICTOR RUIZ",
    text: "Excelente servicio el prestado, las comidas son muy ricas y el servicio al cliente es preciso y acertivo.",
    photo:
      "https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg?size=626&ext=jpg&ga=GA1.2.386372595.1697500800&semt=ais",
    job: "Front developer",
  },
  {
    id: 2,
    name: "OSCAR ALVAREZ",
    text: "Excelente servicio el prestado, las comidas son muy ricas y el servicio al cliente es preciso y acertivo.",
    photo:
      "https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    job: "Front developer",
  },
  {
    id: 3,
    name: "LAURA MONTAÑO",
    text: "Excelente servicio el prestado, las comidas son muy ricas y el servicio al cliente es preciso y acertivo.",
    photo:
      "https://www.inboundcycle.com/hubfs/layout/v3/img/email/gmail-signatures/team-01/svercheval.jpg",
    job: "Front developer",
  },
  {
    id: 1,
    name: "VICTOR RUIZ",
    text: "Excelente servicio el prestado, las comidas son muy ricas y el servicio al cliente es preciso y acertivo.",
    photo:
      "https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg?size=626&ext=jpg&ga=GA1.2.386372595.1697500800&semt=ais",
    job: "Front developer",
  },
  {
    id: 2,
    name: "OSCAR ALVAREZ",
    text: "Excelente servicio el prestado, las comidas son muy ricas y el servicio al cliente es preciso y acertivo.",
    photo:
      "https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    job: "Front developer",
  },
  {
    id: 3,
    name: "LAURA MONTAÑO",
    text: "Excelente servicio el prestado, las comidas son muy ricas y el servicio al cliente es preciso y acertivo.",
    photo:
      "https://www.inboundcycle.com/hubfs/layout/v3/img/email/gmail-signatures/team-01/svercheval.jpg",
    job: "Front developer",
  },
  {
    id: 2,
    name: "LEONARDO MONTOYA DÍAZ",
    text: "Excelente servicio el prestado, las comidas son muy ricas y el servicio al cliente es preciso y acertivo.",
    photo:
      "https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    job: "Consultor Hotelero",
  },
];

const EXPERIENCES = [
  {
    id: 1,
    name: "Servicio de spa",
    description: "Excelente servicio el prestado, las comidas son muy ricas.",
    image:
      "https://theoceansandshotel.ie/wp-content/uploads/2022/06/Ocean_spa_relaxation_room.jpg",
    price: 30,
  },
  {
    id: 1,
    name: "Espacio para bodas",
    description: "Excelente servicio el prestado, las comidas son muy ricas.",
    image:
      "https://images.squarespace-cdn.com/content/v1/56216fc2e4b059c47b425496/405a842e-abfd-405b-9ec2-1972f31ded1c/tendencias+de+bodas+2022",
    price: null,
  },
  {
    id: 1,
    name: "Salon social",
    description: "Excelente servicio el prestado, las comidas son muy ricas.",
    image:
      "https://blog.hiltoncolombia.com/hubfs/articulo-eventos-sociales-hoteles.png",
    price: 30,
  },
  {
    id: 1,
    name: "Salon de eventos",
    description: "Excelente servicio el prestado, las comidas son muy ricas.",
    image:
      "https://res.cloudinary.com/logicaghl/image/upload/v1606946934/Capital_banquete_social_3d598426dd.jpg",
    price: 30,
  },
  {
    id: 1,
    name: "Bar",
    description: "Excelente servicio el prestado, las comidas son muy ricas.",
    image:
      "https://www.hotelfariones.es/backoffice/images/thumbs/medium/510-hotel-fariones-lobby-bar-1.jpg",
    price: 30,
  },
  {
    id: 1,
    name: "Restaurantes",
    description: "Excelente servicio el prestado, las comidas son muy ricas.",
    image:
      "https://granhoteldelaciudaddemexico.com.mx/wp-content/uploads/2020/12/ghcdmx_0005_Brunch-La-Terraza-Gran-Hotel.jpg",
    price: 30,
  },
  {
    id: 1,
    name: "Brunch",
    description: "Excelente servicio el prestado, las comidas son muy ricas.",
    image:
      "https://imagenes.20minutos.es/files/image_990_v3/uploads/imagenes/2022/05/10/m0a0144-3.jpeg",
    price: 30,
  },
  {
    id: 1,
    name: "Areas recreativas",
    description: "Excelente servicio el prestado, las comidas son muy ricas.",
    image:
      "https://z.cdrst.com/foto/hotel-sf/59c9/granderesp/hotel-cartagena-plaza-servicios-865871f.jpg",
    price: 30,
  },
  {
    id: 1,
    name: "Espacio de juegos",
    description: "Excelente servicio el prestado, las comidas son muy ricas.",
    image:
      "https://image-tc.galaxy.tf/wijpeg-9i6ks3xt3lkg6zl9vbit8sz70/casino-slots_wide.jpg?crop=0%2C32%2C612%2C344&width=555",
    price: 30,
  },
  {
    id: 1,
    name: "Tours",
    description: "Excelente servicio el prestado, las comidas son muy ricas.",
    image:
      "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/12/5c/c9/33/quito-tour-bus-hop-on.jpg?w=1200&h=900&s=1",
    price: 30,
  },
];

const SLIDERHOTEL = [
  {
    id: 1,
    title: "Titulo 1",
    text: "Excelente servicio el prestado, las comidas son muy ricas y el servicio al cliente es preciso y acertivo.",
    photo:
      "https://theoceansandshotel.ie/wp-content/uploads/2022/06/Ocean_spa_relaxation_room.jpg",
  },
  {
    id: 2,
    title: "Titulo 2",
    text: "Excelente servicio el prestado, las comidas son muy ricas y el servicio al cliente es preciso y acertivo.",
    photo:
      "https://versushotels.co/_next/image?url=https%3A%2F%2Fimages.prismic.io%2Fversus-hotel%2Ff48b51ac-879b-42c0-a3a0-8bc8f8d24943_Habitaciones3.jpg%3Fauto%3Dcompress%2Cformat&w=1920&q=100",
  },
  {
    id: 3,
    title: "Titulo 3",
    text: "Excelente servicio el prestado, las comidas son muy ricas y el servicio al cliente es preciso y acertivo.",
    photo:
      "https://res.cloudinary.com/dl4sdiukx/image/upload/v1695585959/SUPERIOR%20QUEEN/ejwzc7ujzxt2wvgyzy7x.jpg",
  },
  {
    id: 4,
    title: "Titulo 1",
    text: "Excelente servicio el prestado, las comidas son muy ricas y el servicio al cliente es preciso y acertivo.",
    photo:
      "https://res.cloudinary.com/dl4sdiukx/image/upload/v1695585685/ESTANDAR%20TRIPLE/rr0nvakc0jdmlcfrp61z.jpg",
  },
  {
    id: 5,
    title: "Titulo 1",
    text: "Excelente servicio el prestado, las comidas son muy ricas y el servicio al cliente es preciso y acertivo.",
    photo:
      "https://res.cloudinary.com/dl4sdiukx/image/upload/v1695411432/jcc24yqulf8kiaf9tbtj.jpg",
  },
  {
    id: 6,
    title: "Titulo 1",
    text: "Excelente servicio el prestado, las comidas son muy ricas y el servicio al cliente es preciso y acertivo.",
    photo:
      "https://res.cloudinary.com/dl4sdiukx/image/upload/v1697659746/ahg7dnnnfgcdhe2cjuwd.jpg",
  },
];

const TAGS = [
  { name: "Picante", color: "bg-[#FFF4F4]", icon: <IconChile /> },
  { name: "Sin azucar", color: "bg-[#FFF4F4]", icon: <IconsFood /> },
  { name: "Gluten free", color: "bg-[#F7E6C4]", icon: <IconsNoGluten /> },
];

const ADDITIONALSPRODUCTS = [
  { name: "Refresco de limón", price: "COP 3000", recommended: true },
  { name: "Ensalada de frutas", price: "COP 3500", recommended: false },
  { name: "Agua mineral", price: "COP 2000", recommended: false },
  { name: "Café americano", price: "COP 2500", recommended: false },
  { name: "Batido de mango", price: "COP 4000", recommended: true },
  { name: "Té helado", price: "COP 2800", recommended: true },
  { name: "Empanadas de pollo", price: "COP 3500", recommended: false },
  { name: "Cerveza artesanal", price: "COP 6000", recommended: false },
  { name: "Pizza vegetariana", price: "COP 8000", recommended: true },
  { name: "Hamburguesa gourmet", price: "COP 9000", recommended: true },
  // { name: "Sándwich de pollo", price: "COP 5000", recommended: false },
  // { name: "Sopa de tomate", price: "COP 3500", recommended: false },
  // { name: "Plato de frutas frescas", price: "COP 4500", recommended: true },
  // { name: "Cóctel de camarones", price: "COP 7000", recommended: true },
  // { name: "Pasta al pesto", price: "COP 7500", recommended: false },
  // { name: "Mousse de chocolate", price: "COP 4000", recommended: true },
  // { name: "Tarta de limón", price: "COP 6000", recommended: false },
  // { name: "Bebida energética", price: "COP 3500", recommended: false },
  // { name: "Frappe de café", price: "COP 4500", recommended: true },
  // { name: "Panini de jamón y queso", price: "COP 5500", recommended: false },
];

const RECOMMENDEDPRODUCT = [
  {
    name: "Papas fritas",
    price: "COP 7407.41",
    recommended: true,
    img: "https://t0.gstatic.com/licensed-image?q=tbn:ANd9GcT1rwHV76cR1gwVhjIq9JlfkbZEy6wBK-lg7NhUa__l8L4Klm40-tkX8lpiBWMUDTx0",
  },
  {
    name: "Postre",
    price: "COP 7407.41",
    recommended: true,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfYnNktKOKXL-BpdSaxHDn1z_G2hE8S71lw6c87EvVW1_eczcAM23gemf0j6IK4oSMUXo&usqp=CAU",
  },
  { name: "Vegetales al vapor", price: "COP 7407.41", recommended: true },
  {
    name: "Vino",
    price: "COP 32407.41",
    recommended: true,
    img: "https://descorcha.com/blog/wp-content/uploads/2022/11/tipos-de-vino-tinto-1200x900.jpg",
  },
  {
    name: "Papas fritas",
    price: "COP 7407.41",
    recommended: true,
    img: "https://t0.gstatic.com/licensed-image?q=tbn:ANd9GcT1rwHV76cR1gwVhjIq9JlfkbZEy6wBK-lg7NhUa__l8L4Klm40-tkX8lpiBWMUDTx0",
  },
  {
    name: "Postre",
    price: "COP 7407.41",
    recommended: true,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfYnNktKOKXL-BpdSaxHDn1z_G2hE8S71lw6c87EvVW1_eczcAM23gemf0j6IK4oSMUXo&usqp=CAU",
  },
  { name: "Vegetales al vapor", price: "COP 7407.41", recommended: true },
  {
    name: "Vino",
    price: "COP 32407.41",
    recommended: true,
    img: "https://descorcha.com/blog/wp-content/uploads/2022/11/tipos-de-vino-tinto-1200x900.jpg",
  },
];

const DEFAULT_TEXT = {
  description:
    "Deléitate con nuestro exquisito plato de risotto primavera, una explosión de sabores frescos y aromas cautivadores. Nuestra receta exclusiva combina arroz cremoso con una selección de verduras de temporada, como espárragos frescos, guisantes tiernos y tomates cherry, todo sazonado con hierbas frescas y un toque de queso parmesano. Disfruta de una experiencia culinaria única que te transportará a la primavera en cada bocado.",
  description_cat:
    "Descubre nuestros deliciosos almuerzos gourmet, donde cada plato es una experiencia culinaria excepcional, cuidadosamente preparada para satisfacer tu paladar más exigente",
};

const PRESENTATION_PRODUCT = [
  {
    name: "Sin azucar",
    desc: "0gr de azucar agregada para esta bebida",
    image: true,
  },
  {
    name: "Bajo en azucar",
    desc: "20gr de azucar agregada para esta bebida",
    image: true,
  },
  {
    name: "Alto en azucar",
    desc: "40gr de azucar agregada para esta bebida",
    image: true,
  },
];

const FOODSPECIFICATIONS = [
  {
    name: "Porcion",
    value: "1 Persona",
  },
  {
    name: "Preparación",
    value: "20 Minutos",
  },
];

export {
  PRODUCTS,
  MENU,
  TESTIMONIALS,
  EXPERIENCES,
  SLIDERHOTEL,
  TAGS,
  ADDITIONALSPRODUCTS,
  RECOMMENDEDPRODUCT,
  DEFAULT_TEXT,
  PRESENTATION_PRODUCT,
  FOODSPECIFICATIONS,
};
