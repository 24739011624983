import React, { Fragment } from "react";
// import { UserIcon } from "@heroicons/react/20/solid";

import LogoVersus from "./LogoVersus";
import LogoTerra from "./LogoTerra";
import { classNames } from "tools/ui";
import ShoppingCart from "../components/ui/Shopping Cart";
import { Routes } from "../config/router";
import { Link } from "react-router-dom";
import {
  Bars2Icon,
  Bars3Icon,
  BuildingStorefrontIcon,
  CursorArrowRaysIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import PureModal from "components/Modals/PureModal";
import { signal } from "@preact/signals-react";

const viewNavMobile = signal(false);

const STYLE_HEADER = " w-full relative top-0  py-3 z-10 bg-black";
const property = "versus";

const ctxProperty = {
  versus: {
    logo: <LogoVersus />,
    colorPrimary: "bg-black",
    colorSecundary: "#f2ebe4",
  },
  terra: {
    logo: <LogoTerra />,
    colorPrimary: "bg-black",
    colorSecundary: "#f2ebe4",
  },
};

const Header = ({ baseUrl }) => {
  const NavDesk = () => {
    return (
      <div className="flex space-x-5 justify-end items-center">
        <ul className="hidden md:flex space-x-3 text-white">
          {Object.keys(Routes).map((key) => (
            <li key={key}>
              <Link to={`/${baseUrl}${Routes[key]}`} key={key}>
                {key.charAt(0).toUpperCase() + key.slice(1)}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const NavMobile = () => {
    return (
      <Fragment>
        <Bars3Icon
          className="w-7 md:w-10 text-white"
          onClick={() => (viewNavMobile.value = true)}
        />
      </Fragment>
    );
  };

  const ContentNavMobile = () => {
    return (
      <div className="w-screen h-screen relative">
        <XMarkIcon
          className="w-10 z-10 absolute md:right-5 md:top-5 right-3 top-3 bg-red-400  text-red-800 rounded-full p-2 cursor-pointer"
          onClick={() => (viewNavMobile.value = false)}
        />
        <div className="grid grid-cols-2 relative p-6 place-items-start !items-start gap-3   ">
          {Object.keys(Routes).map((key) => (
            <div
              key={key}
              className="text-center bg-gray-50 rounded-md w-full border border-gray-200 py-10"
            >
              <CursorArrowRaysIcon className="w-12 mx-auto" />
              <Link
                to={`/${baseUrl}${Routes[key]}`}
                key={key}
                onClick={() => (viewNavMobile.value = false)}
              >
                {key.charAt(0).toUpperCase() + key.slice(1)}
              </Link>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const ByMelhous = () => {
    return (
      <div className="flex relative -top-3">
        {/* <span className="text-white font-medium secondary-melhous">by</span>
        <img
          src="https://res.cloudinary.com/dl4sdiukx/image/upload/v1697488998/tecy4smfownu3bghmaqc.png"
          className="h-3 md:h-3 md:mx-auto relative top-1.5"
        /> */}
      </div>
    );
  };

  return (
    <Fragment>
      <header
        className={classNames(STYLE_HEADER, ctxProperty[property].colorPrimary)}
      >
        <div></div>
        <div className="flex justify-between px-5 md:px-14 mx-auto items-center ">
          <div className="flex items-end ">
            {ctxProperty[property].logo}
            <ByMelhous />
          </div>

          <NavDesk />
          <NavMobile />
        </div>
      </header>
      <PureModal
        open={viewNavMobile.value}
        onClose={() => (viewNavMobile.value = false)}
      >
        <ContentNavMobile />
      </PureModal>
    </Fragment>
  );
};

export default Header;
