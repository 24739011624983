import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { useParams } from "react-router-dom";

const Layout = ({ children }) => {
  const { db, id } = useParams();
  const baseUrl = `app/${db}/web_melhous`;

  return (
    <div className="overflow-x-hidden">
      <Header baseUrl={baseUrl} />
      <main className="min-h-[80vh]">{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
