import ScannerQr from "../Components/ScannerQr";
import saleLine from "./ModelSaleLine";
import tools from "tools/dates";
import proxy from "api/proxy";
import store from "store";
import AddProduct from "../Components/AddProduct";
import WizardAddPayment from "../Components/WizardAddPayment";

// import Shipment from "./Shipment";
// import Invoice from "./Invoice";
const ctxSession = store.get("ctxSession");
// const user = ctxSession?.user;

let today = tools.dateToday();
const todayConvert = new Date(today);
let month = todayConvert.setMonth(todayConvert.getMonth() - 1);
let _month = tools.fmtDate2Tryton(new Date(month));
let month_3 = todayConvert.setMonth(todayConvert.getMonth() - 3);
let _month_3 = tools.fmtDate2Tryton(new Date(month_3));
let month_6 = todayConvert.setMonth(todayConvert.getMonth() - 6);
let _month_6 = tools.fmtDate2Tryton(new Date(month_6));

const ComponentAddProduct = ({ record }) => {
  return AddProduct("comment", record);
};
const getReadonly = (record) => {
  if (record?.number || record.state !== "draft") return true;
  return false;
};
const getAddress = (value, record) => {
  const party_id = record?.party?.id;

  let dom = party_id ? [["party", "=", party_id]] : [];
  if (value > 2) {
    dom.push(["rec_name", "ilike", `%${value}%`]);
  }
  return dom;
};
const onChangeAddress = (activeRec, storeRec) => {
  storeRec.invoice_address = storeRec.shipment_address;
  activeRec.invoice_address = activeRec.shipment_address;
};
const getFilters = (session) => {
  return {
    today: [
      [
        "OR",
        ["sale_date", "=", `${today}`],
        ["create_date", ">=", `${today} 00:00:00`],
      ],
      ["agent.user", "=", session.user],
    ],
    month: [
      [
        ["sale_date", "<=", `${today}`],
        ["sale_date", ">=", `${_month}`],
      ],
      ["agent.user", "=", session.user],
    ],
    month_3: [
      [
        ["sale_date", "<=", `${today}`],
        ["sale_date", ">=", `${_month_3}`],
      ],
      ["agent.user", "=", session.user],
    ],
    month_6: [
      [
        ["sale_date", "<=", `${today}`],
        ["sale_date", ">=", `${_month_6}`],
      ],
      ["agent.user", "=", session.user],
    ],
  };
};

const stateColors = {
  none: "slate",
  quotation: "amber",
  processing: "sky",
  done: "lime",
  canceled: "rose",
};
const defaultAgent = async () => {
  const session = store.get("ctxSession");
  const dom = [["user", "=", session.user]];
  const fields = ["id", "rec_name"];
  const { data } = await proxy.search("commission.agent", dom, fields, 1);
  return data[0];
};

const defaultWarehouse = async () => {
  const user = ctxSession?.user;
  console.log(ctxSession, "session");

  const { data, error } = await proxy.search(
    "res.user",
    [["id", "=", user]],
    ["id", "warehouse.name"],
  );
  if (data && data.length > 0) {
    const warehouse = data[0]["warehouse."];
    await store.set({ ...ctxSession, locations: [warehouse.id] });
    return warehouse;
  }
};

function isVisibleSend(name, record) {
  console.log(record, "esto son los records");

  let res = false;
  if (record?.id < 0) {
    res = true;
  }
  return res;
}

const visiblePrint = (name, record) => {
  let res = false;
  if (
    record.state === "draft" ||
    record.state === "processing" ||
    record.state === "transferred" ||
    record.state === "done"
  ) {
    res = true;
  }
  return res;
};

const onPrintBrowser = async (record) => {
  const model = "sale.sale";
  const fieldsActi = ["id", "time_start", "time_end"];
  const session = store.get("ctxSession");
  const terminal = funcs.getCookie();
  const args = { user_id: session.user, device_id: Number(terminal.device_id) };
  const INVOICE_TYPE = {
    P: "SISTEMA POS:",
    1: "FACTURA DE VENTA ELECTRONICA:",
    M: "CORTESIA:",
  };
  const { data: ctxPrinting } = await proxy.methodCall({
    model: model,
    method: "get_printing_context",
    args: [args],
  });
  const argSale = {
    type_doc: "invoice",
    sale_id: record.id,
  };
  const { data: sale } = await proxy.methodCall({
    model: model,
    method: "get_data",
    args: [argSale],
  });
  const styleSeparator =
    "border-top: 1px dashed; margin-top:0.5cm; margin-bottom:0.5cm";

  let infoTransfer = "";
  let infoTransferRoom = "";
  let _sale = { ...record, ...sale };
  _sale.order = sale?.order;
  _sale.payment_term = sale?.payment_term;
  _sale.create_date = new Date(record.sale_date);
  _sale.create_date = _sale.create_date.toLocaleDateString();
  _sale.party_address = record.party.address;
  if (_sale.courtesy) {
    _sale.total_discount_amount = _sale["lines."].reduce((total, item) => {
      return total + item.base_price * item.quantity;
    }, 0);
  }
  let imageQr = "";
  if (sale && sale.qr_code) {
    imageQr = await getQrCode(sale?.qr_code, 30, 30);
  }

  if (record.state !== "transferred" && record.invoice_type != "M") {
    let origins = record["lines."].map((item) => item.origin.id);
    const { data: activity } = await proxy.browse(
      "sale_activity.activity",
      origins,
      fieldsActi,
    );
    // _sale = { ...record };
    _sale.party = record.party.rec_name;
    _sale.party_id_number = record.party.id_number;
    _sale.party_phone = record.party.mobile;

    _sale.untaxed_amount = _sale.total_amount;
    const _saleLine = _sale["lines."].map((act) => {
      let matchingActivity = activity?.find((a) => a.id === act.origin.id);
      let timeStart = matchingActivity?.time_start;
      timeStart = timeStart.slice(0, -3);
      let name = act.product.name;
      const dataInvoice = {
        name: `${timeStart} ${name}`,
        origin: act.origin,
        unit_price_w_tax: act.amount,
        amount_w_tax: act.product.amount_w_tax,
        quantity: act.quantity,
        taxes: act.taxes,
        discount: act.discount,
      };
      return dataInvoice;
    });
    _sale.lines = _saleLine;
  } else {
    infoTransfer =
      "<div style='text-align:center; margin-top: 1.5cm; border-top: 1px dashed;margin-bottom: 0.5cm; padding-top: 0.1cm;'>Firma</div>";

    infoTransferRoom = `<div>${_sale.description}</div>`;
    _sale.party = record.party.rec_name;
    _sale.party_id_number = record["party."].id_number;
    _sale.party_address = record["party."].address;
    _sale.party_phone = record["party."].mobile;
    _sale.payment_term =
      _sale.state == "transferred" ? "Transferido" : "Cortesia";

    let origins2 = record["lines."].map((item) => item.origin);
    let ids = origins2.map((item) => item.id);
    const { data: activity2 } = await proxy.browse(
      "sale_activity.activity",
      ids,
      fieldsActi,
    );
    let _saleLine = _sale["lines."].map((act) => {
      let matchingActivity = activity2.find((a) => a.id === act.origin.id);

      let timeStart = matchingActivity ? matchingActivity?.time_start : "";
      timeStart = timeStart.slice(0, -3);
      let name = act.product.name;
      return {
        name: `${timeStart} ${name}`,
        origin: act.origin,
        unit_price_w_tax: act.amount,
        amount_w_tax: act.amount,
        quantity: act.quantity,
        taxes: act.product.taxes,
        discount: act.discount,
      };
    });

    _sale.lines = _saleLine;
  }
  let html = `
    <div style="margin-top: 0.5cm; margin-bottom: 0.5cm; display: flex; justify-content: space-between; border-top: 1px dashed; border-bottom: 1px dashed; padding: 2mm;">
      <div>Articulo</div>
      <div>Subtotal</div>
    </div>
    ${_sale.lines
      .map(
        (item) => `
      <div style="display: flex; justify-content: space-between;margin-bottom: 0.1cm;">
        <div style="width:70%">${item?.name}  ${
          item?.quantity != 1 ? "x " + item?.quantity : ""
        } ${item?.discount ? "DESC - " + item?.discount : ""}</div>
        <div style="width:30% text-align:rigth">${item?.unit_price_w_tax}</div>
      </div>
    `,
      )
      .join("")}
    <div style="display: flex; justify-content: space-between;">
      <div>Impuestos:</div>
      <div style="text-align: right;">0</div>
    </div>
    <div style="display: flex; justify-content: space-between; margin-top: 0.5cm; ">
    <div>Total:</div>
    <div style="text-align: right;">
    <div style="border-top: 1px dashed; width: 20mm; margin-bottom: 0.1cm; "></div>
    ${_sale.paid_amount || _sale.total_amount}
    </div>
    </div>
    <div style="display: flex; justify-content: space-between;">
    ${
      _sale.courtesy &&
      `<div>Total Complimentary:</div>
      <div style="text-align: right;">
      ${_sale.total_discount_amount}
      </div>`
    }
    </div>
    <div style="margin-top: 0.3cm; text-align: center"><b>Por favor llegar al punto de encuentro 15
    minutos antes de la actividad</b></div>
  `;

  let htmlTax;
  if (record.state != "transferred" && record.invoice_type != "M") {
    htmlTax = `<div>DETALLE DE IMPUESTOS</div>
  <div
    style="${styleSeparator}; border-top:1px dashed ; border-botton:1px dashed; margin-top: 0.5cm;
  margin-bottom: 0.5cm;"
  ></div>
  <div style="display: flex; gap: 0.2cm; flex-direction: column">
    <div style="display: flex; justify-content: space-between">
      <div>MEDIO PAGO</div>
      <div># APROB</div>
      <div>VALOR</div>
    </div>
    ${sale.payments
      .map(
        (item) => `
        <div style="display: flex; justify-content: space-between">
        <div>${item.name}</div>
        <div>${item?.voucher || ""} </div>
        <div>${item.amount} </div>
      </div>
    `,
      )
      .join("")}

  </div>
  <div
    style="${styleSeparator}; border-top:1px dashed ; border-botton:1px dashed; margin-top: 0.5cm;
margin-bottom: 0.5cm;"
  ></div>
  <div style="display: flex; justify-content: space-between">
    <div>No ITEMS:</div>
    <div>${_sale.lines.length}</div>
  </div>
  <div style="display: flex; justify-content: space-between">
    <div>No PEDIDO:</div>
    <div>${_sale.order}</div>
  </div>
  <div style="display: flex; justify-content: space-between">
    <div>CAJA No.:</div>
    <div>${ctxPrinting.sale_device}</div>
  </div>
  <div style="display: flex; justify-content: space-between">
    <div>CAJERO:</div>
    <div>${ctxPrinting.user}</div>
  </div>
  <div style="display: flex; justify-content: space-between">
  <div>VENDEDOR:</div>
  <div>${sale?.agent}</div>
</div>
  <div
  style="${styleSeparator}; border-top:1px dashed ; border-botton:1px dashed; margin-top: 0.5cm;
margin-bottom: 0.5cm;"
></div>
<div>Autorizacion de facturacion ${
      ctxPrinting.authorizations[sale.invoice_type].kind
    } No ${ctxPrinting.authorizations[sale.invoice_type].number} del ${
      ctxPrinting.authorizations[sale.invoice_type].start_date_auth
    } hasta ${
      ctxPrinting.authorizations[sale.invoice_type].end_date_auth
    }, habilita desde ${ctxPrinting.authorizations[sale.invoice_type].prefix}${
      ctxPrinting.authorizations[sale.invoice_type].to_auth
    } a ${ctxPrinting.authorizations[sale.invoice_type].prefix}${
      ctxPrinting.authorizations[sale.invoice_type].from_auth
    }</div>
<div style = "width:100%;  word-wrap: break-word; margin-top: 0.2cm;">CUFE:${
      sale.invoice_type == "1" ? sale.cufe : ""
    }</div>
<div style= "max-width: 140px; margin: 0px 50;">${imageQr}</div>`;
  }
  const title =
    record.state !== "transferred"
      ? INVOICE_TYPE[record.invoice_type]
      : "TRANSFERIDO";
  console.log(sale, "this is sale for get number");
  const number = _sale?.number ?? _sale?.order;
  const typeSystem = ` <div>${title} ${number}</div>`;

  const contenidoHTML = `    <div style="width: 62mm; padding: 0.1cm; font-family: monospace; font-size: 2.8mm">
  <div style="text-align: center; margin-bottom: 0.5cm" >
    <div style="margin-bottom: 0.5cm"><center><img src="https://res.cloudinary.com/dl4sdiukx/image/upload/v1698251404/fc7shgxveracpffl8ie3.png" width="60%" > </center></div>
    <div>${ctxPrinting.company}</div>
    <div>${ctxPrinting.shop}</div>
    <div>NIT: ${ctxPrinting.id_number} ${ctxPrinting.regime_tax}</div>
    <div>${ctxPrinting.street}</div>
    <div>${ctxPrinting.city} Telefono: ${ctxPrinting.phone}</div>
  </div>
  ${typeSystem}
  <div>Fecha: ${_sale.create_date}</div>
  <div style="${styleSeparator}"></div>
  <div>
    <div>Cliente: ${_sale.party}</div>
    ${infoTransferRoom ?? ""}
    ${_sale.description ? `<div>Nombre: ${_sale?.description}</div>` : ""}
    <div>ID: ${_sale.party_id_number}</div>
    <div>Direccion: ${_sale?.party_address || ""}</div>
    <div>Telefono: ${_sale.party_phone}</div>
    <div>Forma de Pago: ${_sale.payment_term}</div>
  </div>
  ${html}
  <div
    style="${styleSeparator}; border-top:1px dashed ; border-botton:1px dashed; margin-top: 0.5cm;
    margin-bottom: 0.5cm;"
  ></div>
  ${htmlTax || ""}
  ${infoTransfer || ""}
  <div>Fecha de creación: ${_sale.create_date} </div>
  <div style="margin-top: 0.5cm; text-align:center">NIT: 900803782-2</div>
  <div style=" text-align:center">SOFTWARE TRYTON - www.presik.com</div>
</div>
<div>
</div>
`;

  const ventana = window.open("", "", "width=500,height=600");
  ventana.document.open();
  ventana.document.write(contenidoHTML);
  ventana.document.close();
  ventana.print();
};

const getTotalAmount = (record) => {
  let totalAmount = 0;
  if (!record.lines && record.total_amount) return record.total_amount;
  record?.lines?.forEach((item) => {
    totalAmount = totalAmount + parseFloat(item.amount_w_tax);
  });
  return totalAmount;
};

const getUntaxedAmount = (record) => {
  let totalAmount = 0;
  record?.lines?.forEach((item) => {
    totalAmount =
      totalAmount +
      parseFloat(
        item.amount ?? parseFloat(item.quantity) * parseFloat(item.unit_price),
      );
  });
  return totalAmount;
};

const visiblePay = (name, record) => {
  let res = false;
  if (
    record.lines &&
    record.lines.size > 0 &&
    record.party &&
    record.residual_amount != 0 &&
    record.state !== "transferred"
  ) {
    res = true;
  }
  return res;
};

const getView = (config) => {
  let DictCtxView = {
    selectable: false, // options: multi - one - false
    activeSearch: true,
    filters: getFilters,
    pagination: [],
    domain: [["untaxed_amount", "=", 0]],
    tags: {
      state: stateColors,
    },
    limit: 50,
    form_action: ["save", "edit", "add"],
    table_action: ["open", "add"],
    model: "sale.sale",
    webfields: {
      number: {
        type: "char",
        readOnly: true,
        searchable: true,
      },
      party: {
        type: "many2one",
        model: "party.party",
        required: true,
        attrs: [
          "invoice_type",
          "sale_price_list",
          "sale_price_list.name",
          "customer_payment_term",
          "customer_payment_term.name",
          "customer_tax_rule",
          "account_receivable",
        ],
        // readOnly: true,
        searchable: true,
      },
      warehouse: {
        type: "many2one",
        model: "party.party",
        // required: true,
        default: defaultWarehouse,
        readOnly: true,
        searchable: true,
      },
      payment_term: {
        type: "many2one",
        required: true,
        model: "account.invoice.payment_term",
        // options: getOptions(),
        translate: true,
        // default: defaultPaymentTerm,
        // readOnly: true,
      },
      sale_date: {
        type: "date",
        readOnly: true,
        searchable: true,
      },
      shipment_date: {
        type: "date",
        required: false,
        readOnly: false,
      },
      total_amount: {
        type: "numeric",
        readOnly: true,
        function: getTotalAmount,
        search: true,
        decimalPlaces: 2,
      },
      shipment_address: {
        type: "many2one",
        model: "party.address",
        recSearch: getAddress,
        withChange: onChangeAddress,
        required: true,
        // readOnly: getReadonly,
        readOnly: false,
      },
      agent: {
        type: "many2one",
        model: "commission.agent",
        recSearch: () => [["user", "=", session.user]],
        default: defaultAgent,
        readOnly: true,
      },
      state: {
        type: "char",
        readOnly: true,
        translate: true,
        tags: stateColors,
      },
      reference: {
        type: "custom",
        // readOnly: true,
        component: ComponentAddProduct,
        // searchable: true,
      },
      invoice_state: {
        type: "char",
        readOnly: true,
        translate: true,
      },
      invoices: {
        type: "button_custom_modal",
        readOnly: true,
        color: "blue",
        search: true,
        desired_action: "count",
      },
      shipments: {
        type: "button_custom_modal",
        readOnly: true,
        color: "blue",
        search: true,
        desired_action: "count",
      },

      shipment_state: {
        type: "char",
        readOnly: true,
        translate: true,
      },
      lines: {
        type: "one2many",
        model: "sale.line",
        ctxView: saleLine.ctxView(config),
        // required: true,
        readOnly: { state: ["quotation"] },
      },
      untaxed_amount: {
        type: "numeric",
        readOnly: true,
        function: getUntaxedAmount,
        search: true,
        decimalPlaces: 2,
      },
      // comment: { type: "text", readOnly: true },
      description: { type: "char" },

      invoice_type: {
        type: "radio",
        default: "1",
        options: [{ value: "1", text: "ELECTRONICA" }],
        readOnly: true,
      },
      residual_amount: {
        type: "numeric",
        readOnly: true,
      },
      paid_amount: {
        type: "numeric",
        readOnly: true,
      },
      comment: {
        type: "char",
        // component: ComponentAddProduct,
        // depends: ["name", "id"],
      },
      send_order: {
        type: "button",
        button_method: "send_order",
        visible: isVisibleSend,
        // save: true,
      },
      print: {
        type: "button",
        method: onPrintBrowser,
        visible: visiblePrint,
        // size: "w-32",
        color: "amber",
        iconRight: "fi fi-rr-print",
      },
      add_payment: {
        type: "button_wizard",
        Component: WizardAddPayment,
        color: "lime",
        icon: "fi fi-rr-add",
        style: "w-10",
        visible: visiblePay,
        resetWizard: false,
      },
      confirm: {
        type: "button",
        button_method: "confirm",
        // visible: getVisible,
        onSuccessMsg: "ORDEN DE COMPRA CONFIRMADA!",
        color: "pink",
      },
    },
    webtree: [
      { name: "number", component: "qr" },
      { name: "sale_date", width: "10%" },
      { name: "party", width: "15%" },
      { name: "reference", width: "10" },
      { name: "untaxed_amount", width: "10%" },
      { name: "state", width: "5%", widget: "circle" },
    ],
    webform: [
      {
        id: "infoSale",
        grid: [
          { name: "number" },
          { name: "invoice_type" },
          { name: "sale_date" },
          { name: "state" },
        ],
        size: [2, 4],
        span: "col-span-2",
      },

      { name: "party" },
      { name: "shipment_address" },
      // { name: "description" },
      { name: "payment_term" },
      { name: "comment" },
      { name: "reference" },
      { name: "lines", component: "modal" },
      // { name: "custom" },
      { name: "agent" },
      { name: "warehouse", size: [2, 2], span: "col-span-2" },
      {
        id: "paid",
        grid: [
          { name: "untaxed_amount", width: "7%" },
          { name: "paid_amount" },
          { name: "residual_amount" },
          { name: "total_amount" },
        ],
        size: [1, 4],
        span: "col-span-2",
      },
      {
        id: "buttons",
        grid: [
          // { name: "confirm" },
          { name: "send_order" },
          { name: "print" },
          { name: "add_payment" },
        ],
        size: [2, 4],
        span: "col-span-2",
      },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
