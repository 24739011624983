import React from "react";

import { tableStyle } from "./constants";
import { classNames } from "tools/ui";

const _style = classNames(
  "calendar-category-row",
  "text-xs text-center text-gray-100",
  tableStyle.categoryStyle,
);

function CategoryRow({ data, prices }) {
  const { categoryStyle } = tableStyle;
  const prices_by_acco = prices[data.accoId];
  console.log("prices_by_acco...", prices_by_acco);
  const categoryCell = prices_by_acco.map((price, index) => {
    // const datefmt = date.format(day, fmtDate);
    let value = "---";
    if (price) {
      value = Math.floor(price / 1000);
      // const prices_by_acco = prices[datefmt];
      // const price = prices_by_acco[data.accoId];
    }

    return (
      <td key={index} className={_style}>
        {value}
      </td>
    );
  });

  return (
    <tr key={data.accoId} className={classNames(categoryStyle)}>
      <td>
        <span className="pl-3 text-sm text-zinc-100 font-bold">
          {data.accoName}
        </span>
      </td>
      {categoryCell}
    </tr>
  );
}

export default CategoryRow;
