import React, { useEffect } from "react";
import { signal } from "@preact/signals-react";
import QRCode from "qrcode";

const qrCode = signal("");
const qrCodeDownload = signal("");

const QrCell = ({ data, field }) => {
  const _value = data.value;

  useEffect(() => {
    const GenerateQr = async () => {
      try {
        const qrSvg = await QRCode.toString(data?.value, {
          type: "svg",
        });
        qrCode.value = qrSvg;

        const qrPng = await QRCode.toDataURL(data?.value, {
          type: "image/png",
          width: 300,
        });
        qrCodeDownload.value = qrPng;
      } catch (error) {
        console.error("Error generating QR code:", error);
      }
    };

    if (data?.value) {
      GenerateQr();
    }
  }, [data]);

  if (data.value) {
    return (
      <div className="flex justify-center flex-col items-center">
        <div
          className="hover:scale-150 transition duration-100 cursor-pointer h-20 w-20"
          dangerouslySetInnerHTML={{ __html: qrCode.value }}
        />

        {qrCodeDownload.value && (
          <a
            href={qrCodeDownload.value}
            download={`QRCode_${field}.png`}
            className="text-blue-500 underline"
          >
            {_value}
          </a>
        )}
      </div>
    );
  } else {
    return false;
  }
};

export default QrCell;
