const config = {
  title: "Nuevo registro de configuracion",
  logo: "https://res.cloudinary.com/dl4sdiukx/image/upload/v1710185075/rjmyr42neqsg3u050y82.png",
  primaryColor: "red",
  secundaryColor: "blue",
  textColor: "black",
  logoMobile: null,
  pqrs: null,
  hotel: {
    title: "Conoce nuestros espacios",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed iaculis ante quis maximus ultricies. Mauris eu turpis eu ligula dictum ullamcorper. Pellentesque sed ultrices metus, a convallis orci. Aliquam viverra posuere neque hendrerit ultrices. Quisque vehicula dui ipsum, et finibus erat malesuada nec.",
    linkButton: "#",
    checkIn: "24:00",
    checkOut: "24:00",
    images: [
      {
        title: "",
        url: "https://res.cloudinary.com/dl4sdiukx/image/upload/v1697659746/ahg7dnnnfgcdhe2cjuwd.jpg",
      },
    ],
    counter: [
      { name: "Habitaciones", count: 20 },
      { name: "Eventos", count: 20 },
      { name: "Experiencias", count: 20 },
      {
        name: "Clientes",
        count: 10,
      },
    ],
    descAmenities:
      "Bienvenido a nuestro hotel, donde te haremos sentir como en casa",
    amenities: [
      { name: "Aire", icon: 20 },
      { name: "Parqueadero", icon: 20 },
      { name: "Wifi", icon: 20 },
      {
        name: "Mascotas",
        icon: 10,
      },
      {
        name: "Zonas verdes",
        icon: 10,
      },
    ],
  },
  restaurant: {
    title: "titulo",
    desc: "Desc",
  },
};

export { config };
