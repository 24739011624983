import React from "react";
import { signal } from "@preact/signals-react";
import ModelProduction from "./model/ModelProduction";

import SearchField from "components/MeForm/SearchField";
import SelectionField from "components/MeForm/SelectionField";
import IntegerField from "components/MeForm/IntegerField";
import CharField from "components/MeForm/CharField";
import StdButton from "components/Buttons/StdButton";
import NumericField from "components/MeForm/NumericField";
import ButtonField from "./components/ButtonField";
import { useWizardStore } from "store/wizardStore";

const ATTRS = { level: "main" };
const orderProduction = signal();
const orderProductionBack = signal();
const product = signal(null);
const baskets = signal(1);
const basketsB = signal(1);
const basketsW = signal(100);
const basketsBW = signal(100);
const basketsTotal = signal(0);
const basketsBTotal = signal(0);
const basketsBWTotal = signal(100);
const base = signal(1);
const baseW = signal(200);
const baseTotal = signal(0);
const total = signal(0);
const neto = signal(0);

const ScreenWeighingInterface = (props) => {
  const { record } = useWizardStore();
  console.log("este es el record", record);
  const { config } = props;
  const handleChanged = (rec) => {
    if (rec) {
      total.value = rec?.move?.weight;
      console.log(rec);
    }
  };
  const orderProductionField = {
    name: "production",
    label: "BUSCAR ORDEN DE PRODUCCION DE ENTRADA",
    model: "production",
    required: true,
    ctxView: ModelProduction.ctxView(config),
  };
  const orderProductionBackField = {
    name: "production",
    label: "BUSCAR ORDEN DE PRODUCCION DE SALIDA",
    model: "production",
    required: true,
    ctxView: ModelProduction.ctxView(config),
  };
  const productField = {
    name: "move",
    label: "SELECCIONAR PRODUCTO",
    model: "stock.move",
    required: true,
    options: [
      { id: "1", name: "Lomo", weight: 100 },
      { id: "2", name: "Carne de murillo", weight: 300 },
      { id: "3", name: "Chatas", weight: 300 },
      { id: "4", name: "Punta de anca", weight: 200 },
    ],
    withChange: handleChanged,
  };

  const basketsField = {
    label: "CANASTAS / A",
    name: "baskets",
    // readOnly: true,
    withChange: null,
  };
  const basketsWField = {
    label: "PESO",
    name: "baskets",
    // readOnly: true,
    withChange: null,
    default: 200,
  };
  const basketsBTotalField = {
    label: "TOTAL",
    name: "baskets",
    // readOnly: true,
    withChange: null,
    default: 200,
  };
  const basketsBField = {
    label: "CANASTAS / B",
    name: "baskets",
    // readOnly: true,
    withChange: null,
  };
  const basketsBWField = {
    label: "PESO UNITARIO",
    name: "baskets",
    // readOnly: true,
    withChange: null,
    default: 200,
  };
  const basketsTotalField = {
    label: "TOTAL",
    name: "baskets",
    // readOnly: true,
    withChange: null,
    default: 200,
  };
  const baseField = {
    label: "BASE",
    name: "baskets",
    // readOnly: true,
    withChange: null,
  };
  const baseWField = {
    label: "PESO UNITARIO",
    name: "baskets",
    // readOnly: true,
    withChange: null,
  };
  const baseTotalField = {
    label: "TOTAL",
    name: "baskets",
    // readOnly: true,
    withChange: null,
  };

  const totalField = {
    label: "TOTAL",
    name: "baskets",
    // readOnly: true,
    withChange: null,
  };

  const netoField = {
    label: "NETO",
    name: "baskets",
    // readOnly: true,
    withChange: null,
  };

  return (
    <div className="p-8 w-full">
      <h1 class="truncate text-4xl font-light text-center mb-20">
        Interface Pesaje
      </h1>
      {/* content  */}
      <div className="max-w-2xl mx-auto space-y-5">
        <ButtonField
          field={orderProductionField}
          data={orderProduction}
          attrs={ATTRS}
          onClick={() => (showModal.value = true)}
        />
        <ButtonField
          field={orderProductionBackField}
          data={orderProductionBack}
          attrs={ATTRS}
          onClick={() => (showModal.value = true)}
        />
        <div className="h-0.5 border-t border-gray-100" />

        <SelectionField field={productField} data={product} attrs={ATTRS} />
        {/* <ButtonField field={productField} data={product} attrs={ATTRS} /> */}
        <StdButton
          content="CAPTURAR PESO"
          color="bluePresik"
          size="w-full"
          onClick={() => console.log("peso total")}
        />
        <div className="flex justify-between items-end space-x-7">
          <IntegerField field={basketsField} data={baskets} attrs={ATTRS} />
          <CharField field={basketsWField} data={basketsW} attrs={ATTRS} />
          <NumericField
            field={basketsTotalField}
            data={basketsTotal}
            attrs={ATTRS}
          />
        </div>
        <div className="flex justify-between items-end space-x-7">
          <IntegerField field={basketsBField} data={basketsB} attrs={ATTRS} />
          <CharField
            field={basketsBWField}
            data={basketsBWTotal}
            attrs={ATTRS}
          />
          <NumericField
            field={basketsBTotalField}
            data={basketsBTotal}
            attrs={ATTRS}
          />
        </div>
        <div className="flex justify-between items-end space-x-7">
          <IntegerField field={baseField} data={base} attrs={ATTRS} />
          <CharField field={baseWField} data={baseW} attrs={ATTRS} />
          <NumericField field={baseTotalField} data={baseTotal} attrs={ATTRS} />
        </div>
        <div className="flex space-x-3">
          <NumericField field={totalField} data={total} attrs={ATTRS} />
          <NumericField field={netoField} data={neto} attrs={ATTRS} />
        </div>
        <div className="flex space-x-4">
          <StdButton
            content="CANCELAR"
            style="mx-auto mt-5 w-full"
            color="rose"
            onClick={() => null}
          />
          <StdButton
            content="GUARDAR PESAJE"
            style="mx-auto mt-5 w-full"
            color="bluePresik"
            onClick={() => null}
          />
        </div>
      </div>
    </div>
  );
};

export default ScreenWeighingInterface;
