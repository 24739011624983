import React from "react";

import FormWizard from "components/MeForm/FormWizard";
import ModelWizardBill from "./ModelWizardBill";
import { useWizardStore } from "store/wizardStore";
import mainStore from "store/mainStore";
import proxy from "api/proxy";

const WizardBill = ({ onClose, parentRec }) => {
  const { store, reset } = useWizardStore();
  const { record, reload } = mainStore();

  async function doInvoice() {
    let foliosIds = [];
    for (const folioId of record.lines.keys()) {
      foliosIds.push(folioId);
    }
    // Charges
    const charges = [];
    let args = [record.id, foliosIds, store.party.id, charges];
    if (store.selectedRows) {
      const charges_ids = Array.from(store.selectedRows.values()).map(
        (row) => row.id,
      );
      args.push(charges_ids);
    }
    const model = "hotel.booking";
    const method = "create_invoice";
    await proxy.methodCall({ model, method, args });
    onClose();
    reset();
    reload();
  }

  return (
    <FormWizard
      key="hotel-booking-wizard-bill"
      ctxView={ModelWizardBill.ctxView()}
      parentRec={{ record: record }}
      handleButton={doInvoice}
    />
  );
};

export default WizardBill;
