import React, { useEffect } from "react";
import { signal } from "@preact/signals-react";
import proxy from "api/proxy";

import TitleSection from "components/Tools/TitleSection";
import PureModal from "components/Modals/PureModal";
import ContentModalExperience from "ext-apps/WebBookingHotel/Components/ContentModalExperience";
import funcs from "tools/functions";

const showModal = signal(false);
const selectExperience = signal(null);
const dataExperiences = signal(null);

const PageExperience = ({ data }) => {
  const handleClick = (exp) => {
    console.log("aca no deb dar click en nada", data);
    selectExperience.value = exp;
    showModal.value = true;
  };

  const getExperiences = async () => {
    const dom = [];
    const fields = [
      "id",
      "experiences.name",
      "experiences.sale_price_w_tax",
      "experiences.list_price",
      "experiences.images",
      "experiences.images.image_url",
      "experiences.description",
    ];
    const { data: dataExp } = await proxy.search("sale.shop", dom, fields);
    console.log("----", JSON.stringify(dataExp, null, 2));
    if (dataExp) {
      dataExperiences.value = dataExp.flatMap((item) => item["experiences."]);
      console.log(dataExperiences.value);
    }
  };

  useEffect(() => {
    getExperiences();
  }, []);

  return (
    <div className="px-5">
      <TitleSection text="EXPERIENCIAS" />
      <div className="grid grid-cols-1  md:grid-cols-5 gap-3  md:w-[100%] rounded-md overflow-hidden my-10 relative">
        {dataExperiences.value &&
          dataExperiences.value.map((exp, index) => (
            <article
              key={index}
              className="h-[400px]  bg-cover flex items-end rounded-lg overflow-hidden shadow-xl relative cursor-pointer active:scale-95 "
              style={{
                backgroundImage: `url(${exp?.["images."]?.[0]?.image_url})`,
              }}
              title={exp.name}
              onClick={() => handleClick(exp)}
            >
              <div className=" bg-gradient-to-t from-black via-black/90 to-transparent px-5  pb-3 pt-10  w-full text-white">
                <h2 className="text-xs truncate w-full text-left font-semibold  melhous-menu-title uppercase">
                  {exp.name}
                  <hr className="border-[0,5] border-gray-700 mb-2 mt-1 w-[70%]" />
                </h2>

                <div className="">
                  <div className="text-left melhous-menu-description">
                    <p className=" line-clamp-2 w-full">{exp.description}</p>
                  </div>
                </div>
              </div>
              {exp.sale_price_w_tax && (
                <div className="text-[13px] text-yellow-500 font-medium text-left text-base absolute top-2 right-2 bg-black px-3 py-1 rounded-full">
                  $ {funcs.fmtMoney(exp.sale_price_w_tax)}
                </div>
              )}
            </article>
          ))}
      </div>

      <PureModal
        open={showModal.value}
        onClose={() => (showModal.value = false)}
      >
        <ContentModalExperience
          exp={selectExperience.value}
          onClose={() => (showModal.value = false)}
        />
      </PureModal>
    </div>
  );
};

export default PageExperience;
