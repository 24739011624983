import { React, useEffect, Fragment } from "react";
import { signal } from "@preact/signals-react";
import { FormattedMessage as FM } from "react-intl";

import RegisterDate from "./RegisterDate";
import proxy from "api/proxy";
import { useWizardStore } from "store/wizardStore";
import { Alert } from "components/Alerts/Alert";
// import ImageAvatar from "assets/img/avatar-masculino.png";

import {
  CheckBadgeIcon,
  CheckIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import StdButton from "components/Buttons/StdButton";
import FaceDetection from "./FaceDetection";
import LoadingIcon from "components/Tools/LoadingIcon";

const timeoutDuration = 20000;
const showAlert = signal(false);
const verificationFace = signal(false);
const currentTime = signal(new Date());
const statusVerification = signal(false);
const reset = signal(false);
const timeLeft = signal(timeoutDuration / 1000);
const TYPE_ALERT = {
  error: {
    style: "bg-red-300 text-red-700",
    icon: (
      <div className="flex-shrink-0 flex items-center justify-center w-8 h-8 bg-white rounded-full mr-2">
        <XMarkIcon className="w-8 h-8 text-red-950" />
      </div>
    ),
  },
  success: {
    style: "bg-green-300 text-green-700",
    icon: (
      <div className="flex-shrink-0 flex items-center justify-center w-8 h-8 bg-white rounded-full mr-2">
        <CheckBadgeIcon className="w-8 h-8 text-green-950" />
      </div>
    ),
  },
};

const Step3 = ({ handleView }) => {
  const { record, store } = useWizardStore();

  const AddEmployeeExit = async (action) => {
    const args = {
      employee_id: store.id,
      option_access: action,
      password: null,
    };
    const response = await proxy.method({
      model: "staff.access",
      method: "set_access_control",
      args: [args],
    });

    if (response) {
      const { msg, status, time } = response.data;

      showAlert.value = {
        text: status === "success" ? `${msg}. Fecha: ${time}` : msg,
        show: true,
        onClose: () => (showAlert.value = { show: false }),
        autoRemove: true,
        color: `${TYPE_ALERT[status].style} text-2xl mt-3 font-semibold`,
        icon: TYPE_ALERT[status].icon,
      };
      statusVerification.value = status;
      if (status === "success") {
        setTimeout(() => {
          showAlert.value = false;
          verificationFace.value = false;
          handleView("step2");
        }, 3000);
      }
    }
  };

  const ContentVerification = () => {
    return (
      <Fragment>
        {verificationFace.value && (
          <div className="flex items-center uppercase space-x-2 text-white bg-[#00726e] !shadow-none text-3xl px-4 py-3 font-semibold">
            <CheckIcon className="w-8 text-white" />
            <span>
              <FM id="attendance_control.verified_user" />
            </span>
          </div>
        )}
        <div className="flex flex-col space-y-1 px-4 text-2xl">
          <span className="font-bold text-3xl truncate">
            {record["party."].name}
          </span>
          <span>
            <span className="font-medium ">Area:</span>{" "}
            {record["department."].name}
          </span>
          <span>
            <span className="font-medium ">
              {" "}
              <FM id="attendance_control.position" />:
            </span>{" "}
            {record["position."].name}
          </span>
        </div>
      </Fragment>
    );
  };

  const ContentNoVerification = () => {
    return (
      <div className="flex flex-col justify-center items-center pt-10 px-4 space-y-8">
        <p className="text-2xl text-center pt-6 font-semibold">
          <FM id="attendance_control.warning_camera" />:
        </p>
        <LoadingIcon size="38px" />
      </div>
    );
  };

  const handleCancel = () => {
    verificationFace.value = false;
    reset.value = true;
    handleView("step2");
  };

  useEffect(() => {
    timeLeft.value = timeoutDuration / 1000;
    const interval = setInterval(() => {
      currentTime.value = new Date();
      if (timeLeft.value <= 1) {
        clearInterval(interval);
        verificationFace.value = false;
        handleView("step2");
        timeLeft.value = 0;
      } else {
        timeLeft.value -= 1;
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [statusVerification, showAlert.value]);

  return (
    <div className="w-full py-5 -mb-10 flex flex-col items-center px-2 mx-auto ">
      <div className="flex space-x-4 text-white items-center text-center pb-8">
        <RegisterDate isSingleLine={true} style={"md:text-3xl"} />
      </div>
      <div className="grid grid-cols-2 w-full justify-items-center max-w-fit gap-x-3 ">
        <div className="bg-white rounded-md border w-fit shadow-md overflow-hidden">
          <FaceDetection
            handleVerification={() => (verificationFace.value = true)}
          />
        </div>

        <div className="flex w-full max-w-md flex-col bg-white border border-gray-600 rounded-md  pb-5 justify-between items-stretch">
          {verificationFace.value ? (
            <ContentVerification />
          ) : (
            <ContentNoVerification />
          )}
          <div className="px-4">
            <StdButton
              style="w-full max-w-md mx-auto from-rose-500 to-rose-500 mt-8 !text-white !h-16 !text-2xl"
              onClick={handleCancel}
              content="attendance_control.cancel"
              color="rose"
            />
          </div>
        </div>
      </div>
      {showAlert.value.show ? (
        <div className="mt-4 w-full max-w-2xl">
          <Alert {...showAlert.value}>{showAlert.value.icon}</Alert>
        </div>
      ) : (
        verificationFace.value && (
          <div className="flex w-full space-x-7 justify-between mt-8 border-t border-gray-600 pt-6 max-w-7xl">
            <div className="w-full flex flex-row space-x-2">
              <StdButton
                style="h-16 min-w-[240px] !from-[#00726e] !to-[#00726e] !text-2xl"
                name="passwd"
                content="attendance_control.income"
                onClick={() => AddEmployeeExit("employee_in")}
              />
              <StdButton
                style="h-16 min-w-[240px] !text-2xl"
                name="passwd"
                color="amber"
                content="attendance_control.start_break"
                onClick={() => AddEmployeeExit("employee_in_rest")}
              />
            </div>
            <div className="flex flex-row space-x-2 w-full items-end justify-end">
              <StdButton
                style="h-16 min-w-[240px]  !text-2xl"
                name="passwd"
                color="amber"
                content="attendance_control.end_break"
                onClick={() => AddEmployeeExit("employee_out_rest")}
              />
              <StdButton
                style="h-16 min-w-[240px] !from-[#00726e] !to-[#00726e] !text-2xl"
                name="passwd"
                content="attendance_control.output"
                onClick={() => AddEmployeeExit("employee_out")}
              />
            </div>
          </div>
        )
      )}
      <div className="text-xl text-gray-400 pt-6">
        <span>
          <FM id="attendance_control.you_have" />{" "}
          <span className="text-[#00726e]">{timeLeft}</span>
          <FM id="attendance_control.warning_recognition" />{" "}
        </span>
      </div>
    </div>
  );
};
export default Step3;
