import React, { useState, useEffect } from "react";
import { classNames } from "tools/ui";

const BaseStyle = "text-left text-white text-5xl";

const RegisterDate = ({ style, isSingleLine = false }) => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formatDateLong = (date) => {
    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${month} ${day} ${year}`;
  };

  return (
    <div>
      {isSingleLine ? (
        <span className={classNames(BaseStyle, style)}>
          {formatDateLong(time)} {time.toLocaleTimeString()}
        </span>
      ) : (
        <>
          <span className={`${BaseStyle} block`}>{formatDateLong(time)}</span>
          <span className={`${BaseStyle} block`}>
            {time.toLocaleTimeString()}
          </span>
        </>
      )}
    </div>
  );
};

export default RegisterDate;
