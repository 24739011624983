import React from "react";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import { classNames } from "tools/ui";
import { Link } from "react-router-dom";

const Button = ({
  text,
  onclick,
  style,
  children,
  className = "",
  url = "",
}) => {
  return (
    <Link
      to={url}
      className={classNames(
        "text-black bg-yellow-500 rounded-full py-2 pr-8 pl-3 flex space-x-3 uppercase max-w-fit items-center ",
        className,
      )}
    >
      {children ? (
        children
      ) : (
        <ArrowLeftIcon className="w-5 text-yellow-500 bg-white rounded-full " />
      )}

      <span className={`melhous-menu-description ${style}`}>{text}</span>
    </Link>
  );
};

export default Button;
