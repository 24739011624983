import React from "react";
import { signal } from "@preact/signals-react";
import TableCell from "components/MeTable/TableCell";
import poolCell from "./poolCell";
import toolsCell from "./toolsCell";

function factoryCell({
  record,
  field,
  parentRec,
  fieldName,
  key,
  model,
  triggerAction,
  onClickCell,
  onChange,
  ctxView,
  level,
}) {
  const { name, type, component = false } = field;
  const align = toolsCell.getAling(field);
  const value = toolsCell.getValue(field, record);
  const readOnly = toolsCell.getReadOnly(field, record, parentRec);
  let color;

  const data = signal(value);
  const attrs = {
    model,
    record,
    level,
    fieldName,
    parentRec,
    onChange,
    ctxView,
    triggerAction,
    readOnly,
  };

  const ComponentCell = poolCell[component || type];
  if (!ComponentCell) {
    console.log("This type of Component there is not exist!!!....", type);
    return null;
  }

  const componentCell = (
    <ComponentCell
      key={key}
      field={field}
      attrs={attrs}
      data={data}
      record={record}
    />
  );

  return (
    <TableCell
      key={name}
      selectable={field.selectable}
      record={record}
      field={field.name}
      align={align}
      onClick={onClickCell}
      color={color}
    >
      {componentCell}
    </TableCell>
  );
}

export default factoryCell;
