import React, { Fragment, useEffect } from "react";
import proxy from "api/proxy";
import { signal } from "@preact/signals-react";
import PureModal from "components/Modals/PureModal";
import { XMarkIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";

const showModal = signal(false);
const _data = signal([]);
const selectItem = signal(undefined);

const ButtonField = (props) => {
  const { field, attrs, onClick } = props;
  const { label, model } = field;

  const getData = async () => {
    try {
      const { data } = await proxy.search(
        model,
        [["state", "!=", "done"]],
        ["reference", "state"],
      );
      if (data) {
        _data.value = data;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (_data.value.length === 0) {
      getData();
    }
  }, []);

  const handleClick = (item) => {
    selectItem.value = item;
    showModal.value = false;
  };

  const handleResetSelect = (e) => {
    console.log(e);
    e.stopPropagation;
    showModal.value = true;
  };

  const Item2 = ({ item }) => {
    return (
      <div
        className="flex flex-col cursor-pointer border-b p-4 rounded-md border border-gray-200 bg-gray-100 relative"
        onClick={() => handleClick(item)}
      >
        {selectItem.value && (
          <MagnifyingGlassIcon
            className="w-8 absolute right-3 top-3"
            onClick={(e) => handleResetSelect(e)}
          />
        )}

        <span>
          <strong>Orden:</strong> {item.reference}
        </span>
        <span>
          <strong>Cantidad:</strong> {item.state}
        </span>
        <span>
          <strong>Proveedor:</strong> {item.state}
        </span>
        <span>
          <strong>Estado:</strong> {item.state}
        </span>
      </div>
    );
  };

  return (
    <Fragment>
      {selectItem.value ? (
        <Item2 item={selectItem.value} />
      ) : (
        <div
          className="flex space-x-3 items-center justify-center bg-gray-300 text-gray-900 p-7 font-semibold text-xl text-center cursor-pointer rounded-md shadow-md"
          onClick={() => (showModal.value = true)}
        >
          <span>{label}</span>
          <MagnifyingGlassIcon
            className="w-10"
            // onClick={() => (showModal.value = true)}
          />
        </div>
      )}
      <PureModal
        open={showModal.value}
        onClose={() => (showModal.value = false)}
        width="w-full mx-5 md:max-w-8xl h-[90vh]"
      >
        <div className="bg-white h-full p-8 relative rounded-md overflow-hidden">
          <XMarkIcon
            className="w-10 absolute right-5 top-5 cursor-pointer"
            onClick={() => (showModal.value = false)}
          />
          <h2 className="text-xl font-bold mb-4">Selecciona el registro</h2>
          <div className="mt-4 ">
            {_data.value.length > 0 ? (
              <div className="grid grid-cols-4 gap-3">
                {_data.value.map((_item) => (
                  <Item2 key={_item.id} item={_item} />
                ))}
              </div>
            ) : (
              <p>No hay registros disponibles.</p>
            )}
          </div>
        </div>
      </PureModal>
    </Fragment>
  );
};

export default ButtonField;
