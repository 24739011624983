import React, { useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

import FormField from "components/MeForm/FormField";
import SearchWindow from "components/Tools/SearchWindow";
import IconButton from "components/Buttons/IconButton";
import ModalForm from "components/Modals/ModalForm";
import { classNames, colors } from "tools/ui";
import { useWizardStore } from "store/wizardStore";
import upStore from "store/upStore";

const style =
  "flex group rounded-md w-full p-2 text-gray-700 border border-slate-300 focus-within:border focus-within:border-cyan-500";

function SearchField(props) {
  const { field, attrs, data } = props;
  const { readOnly, model, ctxView } = field;
  const { level, record, parentRec } = attrs;

  const [placeholder, setPlaceholder] = useState(null);
  const [searchModal, openSearchModal] = useState(false);
  let [openRecord, setOpenRecord] = useState(false);
  let [mode, setMode] = useState(null);

  const { setRecord, newRecord, setTrigger } = useWizardStore();

  let bg = "bg-white";
  if (field.readOnly) {
    bg = colors.readOnly;
  }

  function onSelectedRecord(rec) {
    data.value = rec;
    record[field.name] = rec;
    upStore(level, {
      fieldName: field.name,
      value: rec,
      field: field,
    });
  }

  function searchClicked() {
    if (field.readOnly) {
      return;
    }
    openSearchModal(true);
    setPlaceholder(null);
  }

  async function onChangeView(mode) {
    if (mode === "edit") {
      const recordId = record[field.name].id;
      await setRecord(recordId, model, ctxView);
    } else {
      // create / add
      await newRecord(ctxView, parentRec);
    }
    setTrigger(selectedItem);
    setMode(mode);
    setOpenRecord(true);
  }

  function onCloseSearch() {
    openSearchModal(false);
  }

  function onCloseRecord() {
    setOpenRecord(false);
  }

  function selectedItem(data) {
    onSelectedRecord(data[0]);
  }

  let widgetIcon;
  if (field.widget == "search_add" || field.widget == "search_add_new") {
    if (data.value && field.edit != false) {
      // if (data.value) {
      widgetIcon = (
        <IconButton
          onClick={() => onChangeView("edit")}
          color="blue"
          tooltip="board.button_field_edit"
          name="fi fi-rr-edit"
          style="ml-1"
        />
      );
    } else {
      widgetIcon = (
        <IconButton
          onClick={() => onChangeView("create")}
          color="blue"
          name="fi fi-rr-add"
          tooltip="board.button_field_create"
          style="ml-1"
        />
      );
    }
  }

  if (parentRec) {
    parentRec.target = field.name;
  }

  ctxView.form_action = ["create", "edit"];
  const value = data.value ? data.value.rec_name : "";
  return (
    <FormField {...field}>
      <div className="flex">
        <div className={classNames(style, bg)}>
          <input
            type="text"
            disabled={readOnly}
            name={field.name}
            key={field.name}
            value={value}
            placeholder={placeholder}
            onChange={selectedItem}
            className="w-full focus:border-none focus:outline-none"
          />
          <MagnifyingGlassIcon
            onClick={searchClicked}
            className="h-5 w-5 text-gray-500 active:text-sky-600 cursor-pointer active:scale-90"
            aria-hidden="true"
          />
        </div>
        {widgetIcon}
      </div>
      <SearchWindow
        key="search"
        modeSelect="one"
        open={searchModal}
        onClose={onCloseSearch}
        model={model}
        ctxView={ctxView}
        nameField={field.name}
        onSelectedRecord={onSelectedRecord}
      />
      {openRecord && mode && (
        <ModalForm
          level="wizard"
          open={openRecord}
          ctxView={ctxView}
          mode={mode}
          nameField={field.name}
          parentRec={parentRec}
          onClose={onCloseRecord}
        />
      )}
    </FormField>
  );
}

export default SearchField;
