import { signal } from "@preact/signals-react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import CharField from "components/MeForm/CharField";

const ATTRS = {
  level: "main",
};

const RenderCharFields = (config, parentKey = "") => {
  return Object.entries(config).map(([key, value]) => {
    const fullKey = parentKey ? `${parentKey}.${key}` : key;

    if (typeof value === "string" || typeof value === "number") {
      const fieldConfig = {
        name: fullKey,
        label: key,
        required: false,
      };
      const dataSignal = signal(value);

      return (
        <div key={fullKey} className="mb-4">
          <CharField field={fieldConfig} data={dataSignal} attrs={ATTRS} />
        </div>
      );
    } else if (Array.isArray(value)) {
      return (
        <div
          key={fullKey}
          className="mb-4 col-span-3 grid grid-cols-3 gap-x-4 bg-gray-50 p-4 rounded-md shadow-md relative"
        >
          <h4 className="col-span-3 text-xl">{key}</h4>
          <XMarkIcon className="w-5 h-5 absolute right-2 top-2" />
          {value.map((item, index) => (
            <div
              key={`${fullKey}[${index}]`}
              className="bg-white border shadow-sm mt-2 border-gray-200 px-2 py-3 rounded-md relative space-y-2"
            >
              {RenderCharFields(item, `${fullKey}[${index}]`)}
            </div>
          ))}
        </div>
      );
    } else if (typeof value === "object" && value !== null) {
      return (
        <div key={fullKey} className="mb-4 col-span-3">
          <h4 className="text-xl font-bold uppercase">{key}</h4>
          <div className="ml-4 grid grid-cols-3 gap-x-4">
            {RenderCharFields(value, fullKey)}
          </div>
        </div>
      );
    }

    return null;
  });
};

export default RenderCharFields;
