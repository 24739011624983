import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import QuickForm from "components/Meta/QuickForm";
import ModalMsg from "components/Modals/ModalMsg";
import ModelGuest from "./ModelGuest";
import mainStore from "store/mainStore";
import proxy from "api/proxy";

function FormGuest(props) {
  // window.scrollTo(0, 0); FIXME
  let ctxView = ModelGuest.ctxView();
  console.log("ctxview", ctxView);

  // let [btnDisabled, setBtnDisabled] = useState(true);
  // let [isLoading, setIsLoading] = useState(false);
  const [ctxViewState, setCtxViewState] = useState(ctxView);
  const [openModal, setOpenModal] = useState(null);
  const { store, record, updateStore, updateRecord } = mainStore();
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  async function addGuest() {
    // setIsLoading(true);
    store.country = store.nationality;
    await proxy.saveQuery({
      model: ctxView.model,
      storeRec: store,
    });
    setOpenModal({
      msg: "Huesped agregado exitosamente!",
    });
    props.onAdd();
    // setIsLoading(false);
  }

  function onCloseReturn() {
    setOpenModal(null);
    navigate(-1);
  }

  async function getGuest() {
    let dom = [["id", "=", record.folio]];
    const { data } = await proxy.search("hotel.folio", dom, [
      "guests.name",
      "main_guest.name",
    ]);
    console.log(data, "esto viene");

    if (data.length > 0) {
      if (!data[0]["main_guest."]) {
        record.main_guest = true;
        store.main_guest = true;
      } else {
        let _ctxView = { ...ctxViewState };
        delete _ctxView.webfields.main_guest;
        _ctxView.webform = _ctxView.webform.filter(
          (field) => field.name !== "main_guest",
        );
        setCtxViewState(_ctxView);
      }

      setData(data[0]);
    }
  }

  useEffect(() => {
    getGuest();
  }, []);

  // async function handelCancel() {
  //   setIsLoading(false);
  //   navigate(-1);
  // }

  ctxView.webfields.add_guest.method = addGuest;
  ctxView.webfields.cancel.method = onCloseReturn;

  return (
    <div>
      {data && data["main_guest."] == null && (
        <h2 className="bg-blue-presik text-white rounded-md w-full text-center p-4 shadow-md  text-xl">
          Agrega aca el huesped principal.
        </h2>
      )}
      <QuickForm
        ctxView={ctxViewState}
        level="main"
        onChangeView={onCloseReturn}
      />
      {openModal && (
        <ModalMsg
          open={true}
          onClose={onCloseReturn}
          buttons={["close"]}
          type="success"
          msg={openModal.msg}
        />
      )}
    </div>
  );
}

export default FormGuest;
