import React from "react";
import { signal } from "@preact/signals-react";

import TitleSection from "../../../components/Tools/TitleSection";
import Button from "../components/ui/Button";
import CharField from "components/MeForm/CharField";

// Definir las constantes para cada campo y sus signals
const NameField = {
  type: "char",
  name: "name",
  label: "Nombre completo",
  placeholder: "Nombre completo",
};
const name = signal("");

const EmailField = {
  type: "char",
  name: "email",
  label: "Correo",
  placeholder: "Correo",
};
const email = signal("");

const PhoneField = {
  type: "char",
  name: "phone",
  label: "Teléfono",
  placeholder: "Teléfono",
};
const phone = signal("");

const MobileField = {
  type: "char",
  name: "mobile",
  label: "Celular",
  placeholder: "Celular",
};
const mobile = signal("");

const ComplaintField = {
  type: "char",
  name: "complaint",
  label: "Tipo de queja o reclamo",
  placeholder: "Tipo de queja o reclamo",
};
const complaint = signal("");

const QuestionField = {
  type: "char",
  name: "question",
  label: "Escribe aquí tu pregunta",
  placeholder: "Escribe aquí tu pregunta",
};
const question = signal("");

const PagePqrs = () => {
  return (
    <div className="bg-[#f1eae2] mx-auto py-5 px-5 md:rounded-2xl mt-5 md:mt-32 mb-10">
      <div className="w-full h-full md:rounded-2xl overflow-hidden border border-[#d8d4cf] min-h-[500px] grid grid-cols-1 md:grid-cols-2 justify-center items-center">
        <div>
          <img
            src="https://www.presik.com/static/media/future.58aafd00.png"
            className="max-h-[610px] w-full object-cover"
          />
        </div>
        <div className="py-5 md:px-14 px-5">
          <TitleSection text="Cuéntanos" />
          <p className="text-gray-400 -mt-5 mb-5 text-center melhous-menu-description md:text-xl">
            Envíanos tus quejas y reclamos y te responderemos lo antes posible
          </p>
          <form className="mx-auto grid grid-cols-1 md:grid-cols-2 gap-x-6 md:gap-y-8">
            <CharField
              field={NameField}
              data={name}
              attrs={{ level: "main" }}
              className="!bg-[#f1eae2] w-full outline-none pb-4 border-b border-[#d8d4cf] placeholder:text-gray-800 melhous-menu-description"
            />
            <CharField
              field={EmailField}
              data={email}
              attrs={{ level: "main" }}
              className="!bg-[#f1eae2] w-full outline-none pb-4 border-b border-[#d8d4cf] placeholder:text-gray-800 melhous-menu-description"
            />
            <CharField
              field={PhoneField}
              data={phone}
              attrs={{ level: "main" }}
              className="!bg-[#f1eae2] w-full outline-none pb-4 border-b border-[#d8d4cf] placeholder:text-gray-800 melhous-menu-description"
            />
            <CharField
              field={MobileField}
              data={mobile}
              attrs={{ level: "main" }}
              className="!bg-[#f1eae2] w-full outline-none pb-4 border-b border-[#d8d4cf] placeholder:text-gray-800 melhous-menu-description"
            />
            <CharField
              field={ComplaintField}
              data={complaint}
              attrs={{ level: "main" }}
              className="!bg-[#f1eae2] w-full outline-none pb-4 border-b border-[#d8d4cf] placeholder:text-gray-800 melhous-menu-description"
            />
            <div className="md:col-span-2">
              <CharField
                field={QuestionField}
                data={question}
                attrs={{ level: "main" }}
                className="!bg-[#f1eae2] w-full outline-none pb-4 border-b border-[#d8d4cf] placeholder:text-gray-800 melhous-menu-description"
              />
            </div>
          </form>
          <div className="flex mt-10 flex-col items-center align-middle space-y-3">
            <div className="text-gray-400 melhous-menu-description">
              Estoy de acuerdo con guardar datos{" "}
              <span className="font-medium text-yellow-500">aquí</span>
              <input type="checkbox" className="ml-3" />
            </div>
            <Button align="text-center" text="Enviar PQRS" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PagePqrs;
