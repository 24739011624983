import React from "react";
import { signal } from "@preact/signals-react";

import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";

const currentStep = signal("step1");

function AttendanceControl() {
  const handleView = (view) => {
    if (view) {
      currentStep.value = view;
    }
  };

  const steps = {
    step1: <Step1 handleView={handleView} />,
    step2: <Step2 handleView={handleView} />,
    step3: <Step3 handleView={handleView} />,
  };

  return (
    <div className="px-8 w-full flex overflow-x-hidden relative flex-col items-center justify-start bg-gradient-to-br from-gray-800 via-gray-900 to-gray-900 mx-auto">
      {steps[currentStep.value]}
    </div>
  );
}

export default AttendanceControl;
