import React, { useState } from "react";

import CardRecord from "components/Card/CardRecord";
import Loading from "components/Tools/Loading";
import ModalForm from "components/Modals/ModalForm";
import mainStore from "store/mainStore";
// import Pagination from "./Pagination";

/**
 * Component for create table with body and header
 *
 * @param {object} props - component props
 * @param {boolean} props.sortable - boolean define if column is sortable
 * @param {Objetc} props.ctxView - define model view
 * @param {Array} props.records - Array of records to fill in
 * @param {function} props.updateRecords - Function for update records parent
 * @param {function} props.onClickRow - Function for handle event onClickRow
 * @param {function} props.onClickCell - Function for handle event onClickCell
 * @param {boolean} props.paginate - Define if table is paginate
 * @return {Component} - table component
 */
function GridCards(props) {
  const { ctxView, records, reloadRecords, isLoading, level } = props;
  const { model } = ctxView;
  const [openModalForm, setOpenModalForm] = useState(false);
  const { setRecord } = mainStore();
  const [selectedCard, setSelectedCard] = useState(null);

  function handleDelete(record) {
    props.handleDelete(record);
  }

  function onClose() {
    setOpenModalForm(false);
    if (reloadRecords) {
      reloadRecords();
    }
  }

  function onClick(record) {
    if (record.id > 0) {
      selectedCard;
      if (level === "main") {
        setRecord(record.id, model, ctxView);
      } else {
        console.log("Add code for child here......");
      }
    } else {
      setSelectedCard(record);
    }
    setOpenModalForm(true);
  }

  if (isLoading) return <Loading />;
  return (
    <div id="grid-cards" className="px-2 pb-3 overflow-x-scroll">
      {Array.from(records.values()).map((rec, idx) => {
        return (
          <CardRecord
            key={idx}
            ctxView={ctxView}
            className=""
            record={rec}
            onClick={onClick}
            handleDelete={handleDelete}
          />
        );
      })}
      {openModalForm && (
        <ModalForm
          open={openModalForm}
          ctxView={ctxView}
          onClose={onClose}
          level={level}
        />
      )}
    </div>
  );
}

export default GridCards;
