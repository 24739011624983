import React from "react";
import proxy from "api/proxy";

const PageDefault = React.lazy(() => import("./pages/PageHome"));
const PageNeon = React.lazy(() => import("./pages/PageHomeTwo"));

const type = {
  theme: "default",
};

const MAP_LAYOUT_TO_GROUP = {
  default: PageDefault,
  neon: PageNeon,
};

const Component = MAP_LAYOUT_TO_GROUP[type.theme];

const ScreenMelhous = (session) => {
  // useEffect(() => {
  //   getConfig();
  // }, []);

  const getConfig = async () => {
    const { data, error } = await proxy.search(model, [], ["id", "config"], 1);
    let _data;
    if (data.length > 0) {
      _data = reorganizeConfig(data[0].config);
      updateRecord(_data);
      config.value = data[0];
    } else {
      updateRecord(configBase);
    }
  };

  return <Component variables={type} />;
};

export default ScreenMelhous;
