import React from "react";
import useLangStore from "store/langStore";
import { FormattedMessage as FM } from "react-intl";
import { classNames } from "tools/ui";

const ChangeLang = ({ colors }) => {
  const { changeLang, locale } = useLangStore();
  let border = colors?.colorIcon
    ? `border-[${colors?.colorIcon}]`
    : "border-white";

  let colorText = colors?.colorIcon
    ? `text-[${colors?.colorIcon}]`
    : "text-white";

  const handleLanguageChange = (event) => {
    const newLocale = event.target.value;
    changeLang(newLocale);
  };

  return (
    <div
      className={classNames(
        `border flex  rounded-md min-w-[70px] pr-2`,
        border,
      )}
    >
      <select
        onChange={handleLanguageChange}
        value={locale}
        className={`${
          colors?.colorPrimary || "bg-white"
        } ${colorText} py-1 rounded-md outline-none w-full px-2`}
      >
        <option value="en" className={colorText}>
          <FM id="app.global.english" className={colorText} />
        </option>
        <option value="es" className={colorText}>
          <FM id="app.global.spanish" />
        </option>
      </select>
    </div>
  );
};

export default ChangeLang;
