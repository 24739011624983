import React from "react";
import imgMelhous from "assets/img/logo.png";

// const imgMelhous =
//   "https://www.terrabiohotel.com/wp-content/uploads/2020/03/Logo-TerraBioHotel.png";
const LogoVersus = () => {
  return (
    <img
      src="https://res.cloudinary.com/dl4sdiukx/image/upload/v1729604446/atp5wxkv7hsgntot53di.png"
      className="max-w-[70px]"
    />
  );
};

export default LogoVersus;
