import React, { Fragment } from "react";

const TitleSection = ({
  // style,
  align = "text-center",
  text = "Hola mundo",
  children,
}) => {
  return (
    <Fragment>
      <div
        className={`mt-5 mb-5 flex flex-col items-center px-5 md:px-0  ${align}`}
      >
        <div className="w-fit">
          <h2 className="uppercase md:text-3xl text-xl z-10 relative px-3 melhous-menu-title text-gray-900">
            {text}
          </h2>
          <div className="w-full h-3 bg-yellow-500/50 skew-y-3 relative -top-4 rounded-l-md rounded-r-md " />
        </div>
        {children && <p className="text-sm max-w-7xl">{children}</p>}
      </div>
    </Fragment>
  );
};

export default TitleSection;
