import React from "react";
import { Route } from "react-router-dom";
import { ChartBarIcon } from "@heroicons/react/24/solid";

import DashChart from "pages/Chart/DashChart";
import apps from "./apps";
import proxy from "api/proxy";
import PageRestaurant from "ext-apps/WebMelhous/pages/PageRestaurant";
import PageHotel from "ext-apps/WebMelhous/pages/PageHotel";

function makeRoute(session, modelApp, config) {
  const { app_name, kind } = modelApp;
  if (!apps[app_name]) {
    return [null, null];
  }
  const { Component, Icon, addPath } = apps[app_name];
  let path = app_name;
  if (kind == "external") {
    path = `${app_name}/${addPath ?? ""}`;
  }
  const route = (
    <Route
      key={app_name}
      path={path}
      element={<Component key={app_name} config={config} />}
    />
  );
  let menu;
  if (kind == "internal") {
    menu = {
      name: app_name,
      icon: Icon,
      path: path,
    };
  }
  return [route, menu];
}

async function getRoutes(session) {
  let dom = [["user", "=", session.user]];
  let database = session.db;
  const fields = [
    "user",
    "apps.app_name",
    "apps.icon",
    "apps.kind",
    "apps.active",
    "reports",
  ];

  const { data: records } = await proxy.search("dash.access", dom, fields);
  let routesDash = [];
  let routesApps = [];
  let menus = [];

  if (records?.length > 0) {
    let recs = records[0];
    const accReports = recs.reports;
    if (accReports) {
      let app_name = "charts";
      const routeChart = (
        <Route
          key={app_name}
          path={app_name}
          element={<DashChart key={app_name} accReports={accReports} />}
        />
      );
      routesDash.push(routeChart);
      const menuCharts = {
        name: app_name,
        icon: ChartBarIcon,
        path: `/dash/${app_name}`,
      };
      menus.push(menuCharts);
    }

    const apps = recs["apps."];
    for (const app of apps) {
      if (!app.active) {
        continue;
      }
      const model = "dash.app." + app.app_name;
      const { data: fields_names } = await proxy.getFields(model, [], database);
      const { data: rec } = await proxy.search(
        model,
        [],
        Object.keys(fields_names),
      );
      let config;
      if (rec) {
        config = rec[0];
      }
      const [route, menu] = makeRoute(session, app, config);
      if (!route) {
        continue;
      }
      if (app.kind === "external") {
        if (app.app_name == "web_melhous") {
          const shop_melhous = [
            <Route
              key={"shops"}
              path={`${app.app_name}/shops/:id?`}
              element={<PageRestaurant />}
            />,
            <Route
              key={"hotel"}
              path={`${app.app_name}/hotel/:id?`}
              element={<PageHotel />}
            />,
          ];

          routesApps.push(...shop_melhous);
        }
        routesApps.push(route);
      } else {
        routesDash.push(route);
        menus.push(menu);
      }
    }
  }
  return { menus, routesDash, routesApps };
}

export default getRoutes;
