import { Fragment } from "react";
import { CurrencyDollarIcon } from "@heroicons/react/20/solid";
import ButtomBooking from "../ButtomBooking";
import { FormattedMessage as FM } from "react-intl";
import { useWizardStore } from "store/wizardStore";
import { UserIcon, UsersIcon } from "@heroicons/react/24/solid";

const TYPE_RATE = {
  last_minute_price: "Tarifa de última hora",
  early_bird_price: "Tarifa de reserva anticipada",
  long_stay_price: "Tarifa de larga estadía",
};

const SelectPricesRadio = ({
  ratePlan,
  category,
  roomValues,
  currentNumPeople,
  handleSelectClassification,
  colors,
}) => {
  const { store } = useWizardStore();
  let numAdults = currentNumPeople?.adults ?? 0;
  let numChildren = currentNumPeople?.children ?? 0;
  let _numPeople = numAdults + numChildren;
  let availableRooms = category.rooms.length > 0 ? false : true;
  const ContentPixePrices = ({ prices }) => {
    return (
      <div className="text-xs flex flex-col space-y-1">
        {prices &&
          Object.keys(prices).map((key) => {
            let quantity = key == 1 ? `${key} Persona:` : `${key} Personas:`;
            if (prices[key]) {
              return (
                <div key={key} className="flex space-x-1 ">
                  <span className="flex space-x-1 items-center">
                    {key == 1 ? (
                      <UserIcon className="w-3" />
                    ) : (
                      <UsersIcon className="w-3" />
                    )}{" "}
                    {quantity}
                  </span>
                  <span className="font-semibold">
                    ${prices[key].toLocaleString("es-ES")}
                  </span>
                  <span>Adicional</span>
                </div>
              );
            } else {
              return false;
            }
          })}
      </div>
    );
  };
  if (availableRooms) {
    return (
      <div
        className={`relative min-h-20 w-full h-full left-0 top-0 z-10 flex justify-center items-center ${colors?.colorPrimary || "bg-blue-presik"} ${colors?.colorTextPrimary || "text-white"} `}
      >
        <span className="text-l font-medium">
          No hay habitaciones disponibles para las fechas seleccionadas
        </span>
      </div>
    );
  } else {
    return (
      <div className="w-full relative">
        {availableRooms ? <LayerDark /> : ""}
        <div
          className={`${
            colors?.colorPrimary || "bg-blue-presik"
          } text-white pl-5 pr-5 py-1 `}
        >
          <div>
            <FM id="hotel.booking.plan+rate" />
          </div>
        </div>
        <div className="divide-y divide-gray-200">
          {ratePlan &&
            ratePlan.map((plan, key) => {
              let currentPrice = plan?.pax_prices?.[_numPeople]
                ? plan.unit_price + plan?.pax_prices?.[_numPeople]
                : plan.unit_price;

              if (
                store?.ratePlan &&
                store.ratePlan.price_list !== plan.price_list
              )
                return null;

              return (
                <div
                  key={plan.price_list}
                  className={` ${
                    key % 2 ? "bg-gray-100 text-black" : "bg-white"
                  } relative px-5 py-5 md:py-2 cursor-pointer md:flex space-y-2 md:space-y-0 justify-between items-end md:items-center`}
                >
                  <div className="grid md:grid-cols-3 w-full items-center md:justify-between md:divide-x  divide-gray-300 space-y-2">
                    <div className="text-base font-semibold text-blue-presik ">
                      <span>{plan.name}</span>
                      {category?.rate_type && (
                        <span
                          className={`block text-xs  text-blue-presik font-medium`}
                        >
                          {TYPE_RATE[category?.rate_type] || ""}
                        </span>
                      )}
                    </div>
                    <div className="text-xs text-gray-700 font-normal flex justify-start md:pl-6 mr-6">
                      <FM id="app.booking.includes" />: {plan.name}
                    </div>
                    <div className="md:pl-6 flex flex-col justify-start ">
                      <span className="flex text-base space-x-1 font-semibold">
                        <CurrencyDollarIcon className="w-5" />
                        <span>{currentPrice.toLocaleString("es-ES")}</span>
                      </span>
                      <span className="text-[11px] mt-1">
                        <FM id="hotel.booking.fees_taxes_not_included" />
                      </span>
                      {plan.pax_prices ? (
                        <ContentPixePrices prices={plan.pax_prices} />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="md:w-[282px]">
                    <ButtomBooking
                      category={{
                        ...category,
                        plan: { ...plan, ...{ unit_price: currentPrice } },
                      }}
                      handleSelectClassification={handleSelectClassification}
                      roomValues={roomValues}
                      bgColorPrimary={colors?.colorPrimary || "bg-blue-presik"}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
};

export default SelectPricesRadio;
