// Sale model
import store from "store";

import saleLine from "./ModelSaleLine";
import tools from "tools/dates";
import proxy from "api/proxy";
import ProductDiscountFlex from "./ProductDiscountFlex";
import Taxes from "./Taxes";

let today = tools.dateToday();
const todayConvert = new Date(today);
let month = todayConvert.setMonth(todayConvert.getMonth() - 1);
let _month = tools.fmtDate2Tryton(new Date(month));
let month_3 = todayConvert.setMonth(todayConvert.getMonth() - 3);
let _month_3 = tools.fmtDate2Tryton(new Date(month_3));
let month_6 = todayConvert.setMonth(todayConvert.getMonth() - 6);
let _month_6 = tools.fmtDate2Tryton(new Date(month_6));

const getAgent = async () => {
  const session = await store.get("ctxSession");
  let agent = session.agent;
  if (!agent) {
    const { data } = await proxy.search(
      "commission.agent",
      [["user", "=", session.user]],
      ["rec_name"],
    );
    agent = data[0];
  }
  return agent;
};

const searchParty = (value) => {
  const session = store.get("ctxSession");
  let search = [["OR", ["agents.agent.user", "=", session.user]]];
  if (value) {
    const dom = [
      "OR",
      ["name", "ilike", `%${value}%`],
      ["id_number", "ilike", `${value}%`],
    ];
    search.push(dom);
  }
  return search;
};

const getAddress = (value, record) => {
  const party_id = record?.party?.id;

  let dom = party_id ? [["party", "=", party_id]] : [];
  if (value > 2) {
    dom.push(["rec_name", "ilike", `%${value}%`]);
  }
  return dom;
};

const onChangeAddress = (activeRec, storeRec) => {
  storeRec.invoice_address = storeRec.shipment_address;
  activeRec.invoice_address = activeRec.shipment_address;
};

const onChangeParty = async (record, store) => {
  const party = record.party;
  if (party) {
    store.payment_term = party["customer_payment_term."];
    record.payment_term = party["customer_payment_term."];
    const priceList = party["sale_price_list."];
    store.price_list = party.sale_price_list;
    record.price_list = priceList;
    let invoice_type = "1";
    if (party.invoice_type) {
      invoice_type = party.invoice_type;
    }
    if (!party["customer_payment_term."]) {
      let defaultPayment = await defaultPaymentTerm();
      store.payment_term = defaultPayment;
      record.payment_term = defaultPayment;
    }

    store.invoice_type = invoice_type;
    record.invoice_type = invoice_type;
  }
};

const defaultPaymentTerm = async () => {
  const { data } = await proxy.search(
    "account.invoice.payment_term",
    ["payment_type", "=", 1],
    ["name"],
    1,
  );
  return data[0];
};
const getFilters = (session) => {
  return {
    today: [
      [
        "OR",
        ["sale_date", "=", `${today}`],
        ["create_date", ">=", `${today} 00:00:00`],
      ],
      ["agent.user", "=", session.user],
    ],
    month: [
      [
        ["sale_date", "<=", `${today}`],
        ["sale_date", ">=", `${_month}`],
      ],
      ["agent.user", "=", session.user],
    ],
    month_3: [
      [
        ["sale_date", "<=", `${today}`],
        ["sale_date", ">=", `${_month_3}`],
      ],
      ["agent.user", "=", session.user],
    ],
    month_6: [
      [
        ["sale_date", "<=", `${today}`],
        ["sale_date", ">=", `${_month_6}`],
      ],
      ["agent.user", "=", session.user],
    ],
  };
};

const getTotalAmount = (record) => {
  let totalAmount = 0;
  if (!record.lines && record.total_amount) return record.total_amount;
  record?.lines?.forEach((item) => {
    totalAmount = totalAmount + parseFloat(item.amount_w_tax);
  });
  return totalAmount;
};

const getUntaxedAmount = (record) => {
  let totalAmount = 0;
  record?.lines?.forEach((item) => {
    totalAmount =
      totalAmount +
      parseFloat(
        item.amount ?? parseFloat(item.quantity) * parseFloat(item.unit_price),
      );
  });
  return totalAmount;
};

const getVisible = (name, record) => {
  if (record?.state !== "draft" || record?.number) return false;
  return true;
};

const getReadonly = (record) => {
  if (record?.number || record.state !== "draft") return true;
  return false;
};

const getReadonlyListPrice = (record) => {
  if (
    record?.number ||
    record.state !== "draft" ||
    record?.party?.sale_price_list
  )
    return true;
  return false;
};

const disabledEdit = (record) => {
  let res = false;
  if (record.state === "draft") {
    res = true;
  }
  return res;
};

const stateColors = {
  draft: "slate",
  quotation: "amber",
  processing: "sky",
  done: "lime",
  canceled: "rose",
};

const getView = (config) => {
  const session = store.get("ctxSession");

  let DictCtxView = {
    selectable: false, // options: multi - one - false
    activeSearch: true,
    filters: getFilters,
    access: { edit: disabledEdit },
    domain: [
      ["sale_date", "=", today],
      ["agent.user", "=", session.user],
    ],
    form_action: ["add", "edit"],
    table_action: ["edit", "add"],
    model: "sale.sale",
    tags: {
      state: stateColors,
    },
    webfields: {
      number: { type: "char", readOnly: true },
      party: {
        type: "many2one",
        model: "party.party",
        recSearch: searchParty,
        withChange: onChangeParty,
        required: true,
        readOnly: getReadonly,
        attrs: [
          "invoice_type",
          "sale_price_list",
          "sale_price_list.name",
          "customer_payment_term",
          "customer_payment_term.name",
          "customer_tax_rule",
        ],
        searchable: true,
      },
      sale_date: {
        type: "date",
        readOnly: getReadonly,
        // readOnly: { state: ["quotation"] },
      },
      shipment_date: {
        type: "date",
        required: false,
        readOnly: getReadonly,
      },
      shipment_address: {
        type: "many2one",
        model: "party.address",
        recSearch: getAddress,
        withChange: onChangeAddress,
        required: true,
        readOnly: getReadonly,
      },
      agent: {
        type: "many2one",
        model: "commission.agent",
        default: getAgent,
        readOnly: true,
      },
      price_list: {
        type: "many2one",
        model: "product.price_list",
        readOnly: getReadonlyListPrice,
        // recSearch: () => "[]",
      },
      payment_term: {
        type: "many2one",
        model: "account.invoice.payment_term",
        readOnly: getReadonlyListPrice,
        default: defaultPaymentTerm,
      },
      state: {
        type: "char",
        readOnly: true,
        translate: true,
        default: "draft",
        tags: stateColors,
      },
      invoice_state: {
        type: "char",
        readOnly: true,
        translate: true,
      },
      shipment_state: {
        type: "char",
        readOnly: true,
        translate: true,
      },
      reference: {
        type: "char",
        readOnly: getReadonly,
      },
      // invoice_type: {
      //   type: "radio",
      //   default: "1",
      //   options: [
      //     { value: "P", text: "POS" },
      //     { value: "1", text: "ELECTRONICA" },
      //   ],
      //   readOnly: getReadonly,
      // },
      add_discount: {
        type: "button_wizard",
        Component: ProductDiscountFlex,
        color: "blue",
        icon: "fi fi-rr-add",
        visible: () => {
          return config?.modules_activated?.includes("sale_discount_flex");
        },
        resetWizard: false,
      },
      lines: {
        type: "one2many",
        model: "sale.line",
        ctxView: saleLine.ctxView(config),
        required: true,
        readOnly: getReadonly,
      },
      sale_taxes: {
        type: "custom",
        component: Taxes,
        colspan: "col-span-2",
        // ctxView: ctxViewTaxes,
        readOnly: true,
        search: true,
      },
      total_amount_cache: {
        type: "numeric",
        readOnly: true,
        function: getTotalAmount,
        search: true,
      },
      untaxed_amount: {
        type: "numeric",
        readOnly: true,
        function: getUntaxedAmount,
        search: true,
        decimalPlaces: 2,
      },
      total_amount: {
        type: "numeric",
        readOnly: true,
        function: getTotalAmount,
        search: true,
        decimalPlaces: 2,
      },
      comment: {
        type: "text",
        readOnly: getReadonly,
        colspan: "col-span-2",
      },
      description: {
        type: "char",
        readOnly: getReadonly,
      },
      quote: {
        type: "button",
        button_method: "quote",
        visible: getVisible,
        // save: true,
      },
      // draft: {
      //   type: "button",
      //   method: "draft",
      //   visible: getVisible,
      //   // save: true,
      // },
    },
    webtree: [
      { name: "number", width: "20%" },
      { name: "agent", width: "20%" },
      { name: "sale_date", width: "20%" },
      { name: "party", width: "35%" },
      { name: "total_amount", width: "10%" },
      { name: "invoice_state", width: "10%" },
      { name: "shipment_state", width: "10%" },
      { name: "state", width: "10%", widget: "badge" },
    ],
    webform: [
      { name: "agent" },
      {
        id: "infoSale",
        grid: [
          { name: "shipment_date" },
          { name: "reference" },
          { name: "number" },
        ],
        size: [1, 3],
        span: "col-span-1",
      },
      { name: "party" },
      { name: "shipment_address" },
      {
        id: "infoPrice",
        grid: [{ name: "price_list" }, { name: "payment_term" }],
        size: [1, 2],
        span: "col-span-1",
      },
      // {
      //   id: "infoSale2",
      //   grid: [, { name: "invoice_type" }],
      //   size: [1, 2],
      //   span: "col-span-1",
      // },
      { name: "description" },
      { name: "add_discount" },
      { name: "lines", component: "modal" },
      {
        id: "footerSale",
        grid: [{ name: "sale_taxes" }, { name: "comment" }],
        size: [1, 4],
        span: "col-span-2",
      },
      {
        id: "amounts",
        grid: [
          { name: "untaxed_amount" },
          { name: "total_amount" },
          { name: "state" },
          { name: "quote" },
        ],
        size: [1, 4],
        span: "col-span-2",
      },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
