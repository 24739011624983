import { Fragment, useRef } from "react";
import { signal } from "@preact/signals-react";

import { classNames } from "tools/ui";
import StdButton from "components/Buttons/StdButton";
import { BackspaceIcon, XCircleIcon } from "@heroicons/react/20/solid";
// import DeleteIcon from "components/Icons/DeleteIcon";
// import DeteleCharacterIcon from "components/Icons/DeleteCharacterIcon";

const NUMBERS_DEFAULT = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
const numberCode = signal(null);
const STYLE_BUTTONS =
  "w-full rounded-md text-white p-2 text-xl lg:text-3xl xl:p-3 bg-gray-800 shadow-md active:bg-gray-700 hover:shadow-xl active:scale-95 active:text-white transition-all duration-100";

const OnScreenKeyboard = ({
  numbers = NUMBERS_DEFAULT,
  onClick,
  onCancel,
  limitNumber = 4,
  colorButtons = "",
}) => {
  const inputRef = useRef(null);
  numberCode.value = "";
  const handleKeyPress = (number) => {
    if (!/^\d+$/.test(number)) return;
    let _number = number.toString();
    const _numberCode = numberCode.value ?? "";

    if (_numberCode.length < limitNumber) {
      numberCode.value = _numberCode + _number;
    }

    inputRef.current?.focus();
  };

  const handleDelete = () => {
    let removeNumber = numberCode.value.slice(0, -1);
    numberCode.value = removeNumber;
    inputRef.current?.focus();
  };

  const handleClear = () => {
    numberCode.value = "";
    inputRef.current?.focus();
  };

  const handleInputChange = (event) => {
    numberCode.value = event.target.value;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && numberCode.value) {
      addCode();
    }
  };

  const InputNumberDocument = () => {
    return (
      <input
        // ref={inputRef}
        type="text"
        className="w-full bg-white px-2 py-2 xl:py-3 my-4 !text-4xl placeholder:text:2xl text-center rounded-md shadow-lg border border-zinc-400"
        placeholder="Digita tu código"
        value={numberCode.value}
        maxLength={limitNumber}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        disabled
        // autoFocus
      />
    );
  };

  const addCode = () => {
    onClick(numberCode.value);
  };

  return (
    <Fragment>
      <div className="w-full max-w-2xl mx-auto px-6 rounded-md">
        <InputNumberDocument />
        <div className="grid grid-cols-3 w-full gap-6">
          {numbers.map((number) => (
            <button
              key={number}
              className={classNames(STYLE_BUTTONS, colorButtons)}
              onClick={() => handleKeyPress(number)}
            >
              <span className="flex mt-2 justify-center">{number}</span>
            </button>
          ))}
          <button
            className={classNames(STYLE_BUTTONS, "!bg-gray-200")}
            onClick={() => handleDelete()}
          >
            <BackspaceIcon className="xl:w-14 w-8 mx-auto text-gray-800" />
          </button>
          <button
            className={classNames(STYLE_BUTTONS, "bg-red-200")}
            onClick={() => handleClear()}
          >
            <XCircleIcon className="xl:w-14 w-8 mx-auto text-red-900 " />
          </button>
        </div>
        <div className="pb-4 flex space-x-5">
          <StdButton
            style="w-full max-w-md mx-auto from-rose-500 to-rose-500 mt-8 !text-white !h-16 !text-2xl"
            onClick={onCancel}
            content="CANCELAR"
            color="rose"
          />
          <StdButton
            style="w-full !from-[#00726e] !to-[#00726e] max-w-md mx-auto mt-8 !text-white !h-16 !text-2xl"
            onClick={addCode}
            content="CONFIRMAR"
            color="bluePresik"
          />
        </div>
      </div>
    </Fragment>
  );
};

export default OnScreenKeyboard;
