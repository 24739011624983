import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signal } from "@preact/signals-react";

import proxy from "api/proxy";
import CharField from "components/MeForm/CharField";
import StdButton from "components/Buttons/StdButton";
import ModalMsg from "components/Modals/ModalMsg";
import func from "tools/functions";
import { checkRequiredForm } from "tools/form";
import { useWizardStore } from "store/wizardStore";
import Footer from "pages/Footer";
import { Alert } from "components/Alerts/Alert";

const STYLE_LABEL_ADDRESS = "bg-gray-100 rounded-md shadow-md  px-3 py-1";
const level = "wizard";
const first_name = signal("");
const company = signal("");
const email = signal("");
const mobile = signal("");
const isLoading = signal(false);
const btnDisabled = signal(true); // Inicia en true
const openModal = signal(null);
const error = signal("");
const errorField = signal("");
const showAlert = signal(false);

const ATTRS = {
  level: level,
};

const storeRequired = ["name", "email", "phone"];

function ScreenProspect(props) {
  const navigate = useNavigate();
  const [policyAccepted, setPolicyAccepted] = useState(false); // Nuevo estado

  const fields = {
    firstNameField: {
      name: "name",
      label: "Nombre",
      uppercase: true,
      type: "char",
      required: true,
    },

    emailField: {
      name: "email",
      label: "Email",
      required: true,
    },
    mobileField: {
      name: "phone",
      label: "Teléfono",
      required: true,
    },
    companyField: {
      name: "company",
      label: "Compañia",
      required: true,
    },
  };

  const cleanField = () => {
    first_name.value = "";
    email.value = "";
    mobile.value = "";
    company.value = "";
    isLoading.value = false;
    btnDisabled.value = true; // Resetear a deshabilitado

    errorField.value = "";
    error.value = "";
  };

  async function acceptCreate() {
    const { store, reset } = useWizardStore.getState();
    isLoading.value = true;
    var emailRegex = /^[A-Za-z0-9ñÑ._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    var mobileRegex = /^[0-9]{10}$/;

    if (!emailRegex.test(email.value)) {
      error.value = "Este email no es válido.";
      isLoading.value = false;
      return false;
    }

    if (!mobileRegex.test(mobile.value)) {
      error.value = "Este número no es válido.";
      isLoading.value = false;
      return false;
    }

    if (Object.keys(store).length > 1) {
      const _store = func.recToTryton(store);

      const validation = checkRequiredForm(_store, storeRequired);
      if (!validation) {
        errorField.value = "You need some fields to fill out.";
        isLoading.value = false;
        return false;
      }

      const { data: res, error } = await proxy.create("crm.prospect", _store, [
        "id",
      ]);

      if (res) {
        errorField.value = "";
        isLoading.value = false;
        showAlert.value = true;
        cleanField();
        // localStorage.clear();
        // window.open(
        //   "https://c1.presik.com/app/ACRESHOTEL/web_melhous",
        //   "_blank",
        // );
      } else {
        isLoading.value = false;
        openModal.value = {
          msg: "Error al crear",
        };
      }
    }
  }

  function handlePolicyChange(e) {
    const isChecked = e.target.checked;
    setPolicyAccepted(isChecked);
    btnDisabled.value = !isChecked;
  }

  function onCloseModal() {
    openModal.value = null;
    navigate(1);
  }

  return (
    <div
      className="bg-cover bg-no-repeat"
      style={{
        backgroundImage:
          "url('https://www.presik.com/static/media/future.58aafd00.png')",
      }}
    >
      <div className="flex flex-col item justify-between items-center h-screen backdrop-blur-sm">
        <div className="bg-gray-800 py-5 w-screen">
          <span>
            <a href="https://www.presik.com/">
              <img
                src="https://blog.presik.com/wp-content/uploads/2024/06/logo-presik.webp"
                className="w-32 mx-auto"
              />
            </a>
          </span>
        </div>
        <div className="md:w-[700px] mx-auto  flex flex-col md:justify-center">
          <div className="mx-4 md:mx-0 bg-white p-4 md:p-8 rounded-md shadow-md">
            <h1 className="text-center text-base font-medium text-blue-presik ">
              Da el Primer Paso Hacia la Transformación Digital{" "}
            </h1>
            <form className="md:grid md:grid-cols-2 md:gap-5 w-full">
              <CharField
                field={fields.firstNameField}
                attrs={ATTRS}
                data={first_name}
              />

              <CharField
                field={fields.mobileField}
                attrs={ATTRS}
                data={mobile}
              />
              <CharField field={fields.emailField} attrs={ATTRS} data={email} />
              <CharField
                field={fields.companyField}
                attrs={ATTRS}
                data={company}
              />

              <div className="w-full col-span-2">
                <label
                  htmlFor="name"
                  className=" text-gray-400 mb-2 flex justify-end"
                >
                  <span>
                    {" "}
                    Acepto la{" "}
                    <a
                      href="https://www.presik.com/habeas-data"
                      className="underline text-blue-presik"
                      target="_blank"
                    >
                      política de tratamiento de datos
                    </a>
                    .
                  </span>
                  <input
                    type="checkbox"
                    className="ml-3"
                    onChange={handlePolicyChange} // Agregar handler
                  />
                </label>
              </div>

              {error.value && !errorField.value && (
                <span className="grid py-2 gap-3 justify-center col-span-2 bg-red-300 w-full rounded-md px-20 text-red-800">
                  {error.value}
                </span>
              )}
              {errorField.value && (
                <span className="grid py-2 gap-3 justify-center col-span-2 bg-red-300 w-full rounded-md px-20 text-red-800">
                  {errorField.value}
                </span>
              )}
              <div className="grid py-5 gap-3 justify-center col-span-2">
                <StdButton
                  key="add"
                  color="bluePresik"
                  disabled={btnDisabled.value}
                  loading={isLoading.value}
                  onClick={acceptCreate}
                  size="w-full md:w-[150px]"
                  content="ENVIAR"
                />
              </div>
              <Alert
                text="Mensaje enviado con éxito. Nuestro equipo te responderá en breve."
                show={showAlert.value}
                onClose={() => (showAlert.value = false)}
                color="bg-green-300 text-green-800"
                position="fixed bottom-10 right-10"
              />
            </form>
          </div>
        </div>
        <div className="bg-gray-800 w-full">
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default ScreenProspect;
