import { useEffect } from "react";
import { signal } from "@preact/signals-react";
import store from "store";
import mainStore from "store/mainStore";

import proxy from "api/proxy";
import { config as configBase } from "ext-apps/WebMelhous/config";
import ImageQr from "components/Tools/ImageQr";
import StdButton from "components/Buttons/StdButton";
import RenderCharFields from "./Components/RenderCharFields";
import { processConfig, reorganizeConfig } from "./Components/CleanValues";
import { Alert } from "components/Alerts/Alert";

const model = "melhous";
const config = signal(configBase);
const showAlert = signal(false);

const ScreenMelhousDash = (props) => {
  const _session = store.get("ctxSession");
  const { db } = _session;
  const baseUrl = `${window.location.protocol}//${window.location.host}/app/${db}/web_melhous`;

  // const { store, record, updateRecord } = useMainStore();
  const { store: storeRec, record, updateRecord } = mainStore();

  const addConfig = async () => {
    const _record = processConfig(
      { ...record },
      {
        prefixToRemove: "v.", // Eliminar el prefijo "v."
        charToRemove: "s", // Eliminar todas las letras "s"
      },
    );
    console.log(_record, " ---- records ");

    const fields_names = ["id"];
    const toStore = {
      config: _record || config,
      title: _record.title || config.title,
    };
    const res = await proxy.create(model, toStore, fields_names);
    if (res) {
      showAlert.value = true;
    }
  };

  const getConfig = async () => {
    const { data, error } = await proxy.search(model, [], ["id", "config"], 1);
    let _data;
    if (data.length > 0) {
      _data = reorganizeConfig(data[0].config);
      updateRecord(_data);
      config.value = data[0];
    } else {
      updateRecord(configBase);
    }
  };

  useEffect(() => {
    getConfig();
  }, []);

  return (
    <div className="w-full p-4">
      <h2 className="text-2xl font-bold">Configuracion para Melhous</h2>
      <div className="flex justify-between space-x-10 items-start ">
        <div className="w-4/5 relative ">
          <div className="grid grid-cols-3 gap-x-4  ">
            {RenderCharFields(record)}
          </div>
          <Alert
            text="Guardado correctamente"
            show={showAlert.value}
            onClose={() => (showAlert.value = false)}
            color="bg-green-200 text-green-800"
          />
          <div className="sticky bottom-0 pt-8 pb-3 bg-gradient-to-t from-white from-5% via-white w-full pointer-events-none ">
            <StdButton
              onClick={addConfig}
              color="blue"
              size="full"
              style="mx-auto uppercase pointer-events-auto"
            >
              Crear configuración
            </StdButton>
            <StdButton
              onClick={() => console.log(record)}
              color="blue"
              size="full"
              style="mx-auto uppercase pointer-events-auto"
            >
              vER RECORD
            </StdButton>
          </div>
        </div>
        <div className="w-1/5 bg-gray-100 p-4 rounded-md shadow-md space-y-5 divide divide-y place-content-center sticky top-1">
          <ImageQr value={baseUrl} name="qrHotels" title="Qr para Melhous" />
          <ImageQr
            value={`${baseUrl}/shops`}
            name="qrHotels"
            title="Qr para hotel"
          />
          <ImageQr
            value={`${baseUrl}/hotel`}
            name="qrHotels"
            title="Qr para restaurante"
          />
        </div>
      </div>
    </div>
  );
};

export default ScreenMelhousDash;
