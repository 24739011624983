import React, { useState } from "react";
import store from "store";

import BoardHeader from "./BoardHeader";
import QuickTable from "./QuickTable";
import QuickForm from "components/Meta/QuickForm";
import QuickCards from "./QuickCards";
import mainStore from "store/mainStore";
import { classNames } from "tools/ui";

const VIEWS = {
  list: QuickTable,
  form: QuickForm,
  cards: QuickCards,
};

/**
 * This component renders a table or a form from a context view object
 *
 * @param {object} props - component props
 * @param {Function} props.ctxView - object to render view
 * @returns {Component}
 */

function Board(props) {
  const session = store.get("ctxSession");
  const { ctxView, returnFilter, currentView } = props;
  let startView = "list";
  if (ctxView.defaultView) {
    startView = ctxView.defaultView;
  }
  const [viewType, setViewType] = useState(startView);
  const [action, setAction] = useState(null);
  const { setRecord, newRecord, reset } = mainStore();

  async function onChangeView(event, action, record) {
    let _viewType = viewType;
    if (action !== "return") {
      if (!record) {
        await newRecord(ctxView);
      } else {
        await setRecord(record.id, ctxView.model, ctxView);
      }
    } else reset(true);

    if (["edit", "add"].includes(action)) {
      _viewType = "form";
    } else if (["editModal", "addModal"].includes(action)) {
      _viewType = "modalForm";
    } else {
      _viewType = "list";
    }
    setViewType(_viewType);
    if (props.currentView) {
      props.currentView(_viewType);
    }
  }

  function onClickAction(event, action) {
    setAction(action);
  }

  let style = "w-full";
  if (props.style) {
    style = props.style;
  }

  // console.log("here.......", viewType);
  const View = VIEWS[viewType];

  return (
    <div id="board" className={classNames("mx-3 my-3 lg:mx-4", style)}>
      <BoardHeader
        ctxView={ctxView}
        viewType={viewType}
        onChangeView={onChangeView}
        onClickAction={onClickAction}
      />
      <View
        ctxView={ctxView}
        onChangeView={onChangeView}
        session={session}
        action={action}
        level="main"
        returnFilter={returnFilter}
      />
    </div>
  );
}

export default Board;
