import React, { useEffect } from "react";
import { signal } from "@preact/signals-react";
import QRCode from "qrcode";

const qrCode = signal("");
const qrCodeDownload = signal("");

const ImageQr = ({ value, name, size = 200, title = null }) => {
  useEffect(() => {
    const GenerateQr = async () => {
      try {
        const qrSvg = await QRCode.toString(value, {
          type: "svg",
          width: size,
        });
        qrCode.value = qrSvg;

        const qrPng = await QRCode.toDataURL(value, {
          type: "image/png",
          width: size,
        });
        qrCodeDownload.value = qrPng;
      } catch (error) {
        console.error("Error generating QR code:", error);
      }
    };

    if (value) {
      GenerateQr();
    }
  }, []);

  return (
    <div className="flex justify-center flex-col items-center">
      {title && <span className="font-medium my-3">{title}</span>}
      <div
        className="hover:scale-150 transition duration-100 cursor-pointer"
        dangerouslySetInnerHTML={{ __html: qrCode.value }}
      />

      {qrCodeDownload.value && (
        <a
          href={qrCodeDownload.value}
          download={`QRCode_${name}.png`}
          className="text-blue-presik mt-3 underline"
        >
          Descargar QR
        </a>
      )}
    </div>
  );
};

export default ImageQr;
