import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { FormattedMessage as FM } from "react-intl";

import FormField from "./FormField";
import upStore from "store/upStore";
import { classNames, colors } from "tools/ui";

/**
 *
 * @param {*} props -
 * @param {Boolean} selection -
 * @param {String} name -
 * @param {String} label -
 * @param {String} value -
 * @param {Function} onChange -
 * @param {Boolean} required -
 * @param {Map} options -
 * @returns Component DropDown
 */

const style =
  "h-10 text-left text-sm border border-slate-300 appearance-none pt-2 pb-2 px-3 w-full rounded-md focus:outline-none focus:border focus:border-cyan-500 min-w-[100px]";

function SelectionField(props) {
  const { field, attrs, data } = props;
  const { name, readOnly } = field;
  const { level, ctxView } = attrs;

  let color = "bg-white";
  let disabled = "";
  if (readOnly) {
    color = colors.readOnly;
    disabled = "pointer-events-none";
  }

  function selectedItem(item) {
    data.value = item;
    upStore(level, {
      fieldName: name,
      value: data.value,
      field: field,
    });
  }

  const options = field.options ?? [];

  let selected = "";
  let selectedName = "";
  if (data && data.value) {
    selected = data.value;
    selectedName = `${ctxView?.model}.${data.value.name}`;
  }

  function getStyle(selected) {
    return classNames(
      selected ? "font-semibold" : "font-normal",
      "block truncate",
    );
  }

  return (
    <FormField {...field}>
      <Listbox value={selected} onChange={selectedItem}>
        {({ open }) => (
          <Fragment>
            <div className="relative">
              <Listbox.Button className={classNames(style, color, disabled)}>
                {selected && (
                  <span className="block truncate">
                    {selected &&
                    field.translate &&
                    typeof selected === "object" ? (
                      <FM id={selectedName} />
                    ) : (
                      selected.name
                    )}
                  </span>
                )}
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white border border-gray-100">
                  {options.map((option) => (
                    <Listbox.Option
                      key={option.id}
                      className={({ active }) =>
                        classNames(
                          active
                            ? "text-blue-800 bg-blue-200"
                            : "text-gray-800",
                          "relative cursor-default select-none py-2 pl-8 pr-4",
                        )
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <Fragment>
                          <span className={getStyle(selected)}>
                            {field.translate && option ? (
                              <FM id={`${ctxView.model}.${option.name}`} />
                            ) : (
                              option.name
                            )}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? "text-blue-800" : "text-blue-7d00",
                                "absolute inset-y-0 left-0 flex items-center pl-1.5",
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </Fragment>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Fragment>
        )}
      </Listbox>
    </FormField>
  );
}

export default SelectionField;
