import React, { Fragment, useState, useEffect } from "react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import { Combobox } from "@headlessui/react";

import { classNames, colors } from "tools/ui";
import upStore from "store/upStore";
import proxy from "api/proxy";

const style =
  "border border-slate-300 appearance-none pt-2 pb-2 px-2 w-full rounded-md focus:outline-none focus:border focus:border-cyan-500 h-10";

var timeoutId;

/**
 * Field Form Selection.
 *
 * @param {object} props - component props
 * @param {string} props.name - field name
 * @param {string} props.label - field label
 * @param {boolean} props.readOnly - whether field is read-only
 * @param {string} props.model - model name to search for options
 * @param {string} props.placeholder - string for view in placeholder
 * @param {function} props.recSearch - function to generate search domain based on query and current record
 * @param {Array} props.options - list of options if model is not provided
 * @param {Array} props.attrs - list of fields to include in the option objects if model is provided
 * @param {object} props.record - current record object
 * @param {function} props.onChange - callback function called when field value changes
 * @param {any} props.value - field value
 * @return {Component} - selection component
 */
export default function DropdownCell(props) {
  const { field, record, data, parentRec, level } = props;

  if (!field.editable) {
    return data.value?.rec_name || data.value?.name;
  }

  const { name, model, recSearch, parentRecord, attrs, readOnly, placeholder } =
    field;
  const [options, setOptions] = useState(null);
  const field_ = field.recName ?? "rec_name";
  let fields = ["id", field_];
  if (attrs) {
    fields = [...fields, ...attrs];
  }

  async function getOptions(_data) {
    let domain = [];
    let limit = 50;
    if (recSearch) {
      domain = await recSearch(_data, record, parentRec);
    } else if (_data && _data !== "") {
      limit = 200;
      domain.push([field_, "ilike", `%${_data}%`]);
    }
    const { data: options } = await proxy.search(model, domain, fields, limit);
    setOptions(options);
  }

  const onOpen = () => {
    if (typeof field.options === "function") {
      setOptions(field.options(record));
    } else {
      getOptions();
    }
  };

  const handleField = (value) => {
    data.value = value;
    const args = {
      field,
      parentRec,
      recId: record.id,
      fieldName: field.name,
      value: data.value,
    };
    upStore(level, args);
  };

  function onChangeInput(event) {
    const data = event.target.value;
    // setTargetText(data);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    if (data.length > 2) {
      timeoutId = setTimeout(getOptions, 1000, data);
    }
  }

  let nameRec = "";
  if (data && data.value && typeof data.value === "object") {
    nameRec = data.value.name ?? data.value.rec_name ?? "";
  } else if (record) {
    let _name = name;
    // WE NEED FIX, DONT USE "number"
    if (record && typeof record[name] === "number") {
      _name = `${name}.`;
    }
    if (record[_name]) {
      nameRec = record[_name].name || record[_name].rec_name;
    }
  }

  let color = "";
  let styleDisabled = "";
  if (readOnly) {
    color = colors.readOnly;
    styleDisabled = "pointer-events-none";
  }

  return (
    <Combobox as="div" value={nameRec} onChange={handleField}>
      <div className="relative">
        <Combobox.Input
          disabled={readOnly}
          readOnly={readOnly}
          className={classNames(style, color)}
          onChange={onChangeInput}
          displayValue={nameRec}
          placeholder={placeholder}
        />
        <Combobox.Button
          disabled={readOnly}
          readOnly={readOnly}
          onClick={onOpen}
          className={classNames(
            "absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none",
            styleDisabled,
          )}
        >
          <ChevronDownIcon
            className="h-3.5 w-3.5  text-slate-500 stroke-3 stroke-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {options?.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {options.map((option) => (
              <Combobox.Option
                key={option.id}
                value={option}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-cyan-500 text-white" : "text-gray-900",
                  )
                }
              >
                {({ active, selected }) => (
                  <Fragment>
                    <span
                      className={classNames(
                        "block truncate",
                        selected && "font-semibold",
                      )}
                    >
                      {option.name || option.rec_name}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-indigo-600",
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </Fragment>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
