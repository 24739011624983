import React, { useRef, useEffect, Fragment } from "react";
import { signal } from "@preact/signals-react";
import * as faceapi from "face-api.js";

const photo = signal(null);
const captured = signal(null);
const intervalId = signal(null);

const FaceDetection = ({ handleVerification }) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    captured.value = null;
    photo.value = null;
  }, []);

  useEffect(() => {
    const loadModelsAndStartVideo = async () => {
      try {
        await faceapi.nets.tinyFaceDetector.loadFromUri("/models");
        await faceapi.nets.faceLandmark68Net.loadFromUri("/models");

        const stream = await navigator.mediaDevices.getUserMedia({ video: {} });
        videoRef.current.srcObject = stream;

        videoRef.current.addEventListener("play", handleVideoPlay);
      } catch (err) {
        console.error(
          "Error al acceder a la cámara o cargar los modelos: ",
          err,
        );
      }
    };

    loadModelsAndStartVideo();

    return () => {
      stopCamera();
    };
  }, []);

  const handleVideoPlay = async () => {
    const detectFaces = async () => {
      if (videoRef.current && canvasRef.current && !captured.value) {
        const options = new faceapi.TinyFaceDetectorOptions({
          scoreThreshold: 0.75,
        });
        const detections = await faceapi.detectAllFaces(
          videoRef.current,
          options,
        );

        if (detections.length > 0) {
          const displaySize = {
            width: 640,
            height: 480,
          };
          faceapi.matchDimensions(canvasRef.current, displaySize);

          const resizedDetections = faceapi.resizeResults(
            detections,
            displaySize,
          );
          const ctx = canvasRef.current.getContext("2d");
          ctx.clearRect(0, 0, 640, 480);
          faceapi.draw.drawDetections(canvasRef.current, resizedDetections);
          clearInterval(intervalId);
          capturePhoto();
          captured.value = true;
          handleVerification(true);
        }
      }
    };

    if (!captured.value) {
      clearInterval(intervalId.value);
      intervalId.value = setInterval(await detectFaces, 700);
    }
  };

  const capturePhoto = () => {
    if (videoRef.current) {
      const tempCanvas = document.createElement("canvas");
      tempCanvas.height = 480;
      tempCanvas.width = 640;
      const tempCtx = tempCanvas.getContext("2d");
      tempCtx.drawImage(videoRef.current, 0, 0, 640, 480);
      setTimeout(() => {
        tempCanvas.toBlob((blob) => {
          const photoURL = URL.createObjectURL(blob);
          photo.value = photoURL;
          captured.value = true;
          stopCamera();
        });
      }, 300);
    }
  };

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject;
      stream.getTracks().forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
  };

  return (
    <div className="max-w-md h-auto relative">
      {photo.value ? (
        <img src={photo.value} alt="captured face" />
      ) : (
        <Fragment>
          <video ref={videoRef} autoPlay muted />
          <canvas
            ref={canvasRef}
            className="max-w-md h-auto left-0 top-0 absolute"
          />
        </Fragment>
      )}
    </div>
  );
};

export default FaceDetection;
