import React, { Fragment, useEffect } from "react";
import { QrReader } from "react-qr-reader";
import { signal } from "@preact/signals-react";

import proxy from "api/proxy";

import PureModal from "components/Modals/PureModal";
import { QrCodeIcon } from "@heroicons/react/20/solid";
import StdButton from "components/Buttons/StdButton";
import { useIdSeq } from "store/idSeq";
import mainStore from "store/mainStore";

const showModal = signal(false);
const product = signal(null);

const AddProduct = (name, rec = []) => {
  const { seq, increment } = useIdSeq();
  const {
    store: storeRecord,

    updateRecord,
    updateStore,
    record,
  } = mainStore();

  let isFetching = false;

  const getData = async (code) => {
    if (code && !isFetching) {
      isFetching = true;
      const { data: res } = await proxy.search(
        "product.product",
        [["code", "=", code]],
        [
          "id",
          "list_price",
          "list_prices",
          "rec_name",
          "name",
          "code",
          "quantity",
          "cost_price",
          "sale_price_w_tax",
          "sale_uom.rec_name",
          "default_uom.rec_name",
          "default_uom.name",
          "reference",
        ],
      );

      if (res.length > 0) {
        product.value = res[0];
      }
      isFetching = false;
    }
  };

  const handleQr = (result, error) => {
    if (result) {
      getData(result?.text);
    }

    if (error) {
      console.info(error);
    }
  };

  const RowSection = ({ name = "", value = "" }) => {
    return (
      <div className="flex justify-between space-x-2 py-2">
        <span className="font-medium">{name}</span>
        <span>{value}</span>
      </div>
    );
  };
  const addLineClose = async () => {
    const response = await addLine();
    if (response) {
      showModal.value = false;
    }
  };

  const addLine = () => {
    const _storeRecord = { ...storeRecord };
    let _activeRecord = { ...record };
    let line_ = {
      id: seq,
      code: product.value.code,
      reference: product.value.reference,
      // name: product.value.name,
      unit_price: product.value.list_price || 0,
      sale_price_taxed: product.value.sale_price_w_tax || 0,
      product: product.value,
      quantity: 1,
      unit: product.value?.["sale_uom."],
      listing: true,
    };

    let lineStore_ = {
      id: seq,
      product: product.value.id,
      unit: product.value["sale_uom."],
      unit_price: product.value.list_price || 0,
      base_price: product.value["sale_price_w_tax"] || 0,
      // sale_price_w_tax: product["sale_price_w_tax"] || 0,
      quantity: 1,
      unit: product.value?.["sale_uom."].id,
    };

    let lines = _activeRecord.lines;

    if (!_storeRecord.lines) {
      _storeRecord.lines = new Map();
      _storeRecord.lines.set("create", new Map());
    }
    let to_create = _storeRecord.lines.get("create");
    to_create.set(seq, lineStore_);
    lines.set(seq, { ...line_ });
    increment(seq + -1);
    updateRecord(_activeRecord);
    updateStore(_storeRecord);
    product.value = null;
    return true;
  };
  const ContentModal = () => {
    const exist = product?.value?.quantity > 0 ? true : false || false;

    return (
      <Fragment>
        <div className="grid grid-cols-2 overflow-hidden rounded-md">
          <QrReader
            onResult={handleQr}
            containerStyle={{ padding: "0px" }}
            videoContainerStyle={{ paddingTop: "0px", height: "336px" }}
          />
          <div className="p-4 flex flex-col justify-between">
            <div className="flex flex-col">
              <div className="flex justify-between mb-6">
                <span className="text-xl">Detalle del producto</span>
                {product && product.value?.code && (
                  <span className="bg-blue-presik text-white py-1 px-4 rounded-full">
                    # {product.value.code}
                  </span>
                )}
              </div>
              {product?.value && (
                <div className="divide-y divide-gray-200 border-y border-gray-200">
                  <RowSection name="Nombre" value={product.value.name} />
                  <RowSection
                    name="Precio"
                    value={product.value.sale_price_w_tax || 0}
                  />
                  <RowSection
                    name="Referencia"
                    value={product.value.reference}
                  />
                  <RowSection name="Cantidad" value={product.value.quantity} />
                </div>
              )}
            </div>
            <div className="px-10 pt-3  flex flex-col items-stretch">
              {exist && (
                <div className="flex space-x-2">
                  <StdButton
                    size="w-full"
                    content="AGREGAR"
                    onClick={addLine}
                    disabled={!exist}
                    color={!exist ? "rose" : "blue"}
                  />
                  <StdButton
                    size="w-full"
                    content="AGREGAR Y CERRAR"
                    onClick={addLineClose}
                    disabled={!exist}
                    color={!exist ? "rose" : "blue"}
                  />
                </div>
              )}

              {!exist && product.value && (
                <div className="text-red-950 text-center rounded-md mt-3">
                  No existe este producto en esta bodega
                </div>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  useEffect(() => {
    if (showModal.value) {
      console.log("paso por aca");
      product.value = null;
    }
  }, [showModal.value]);

  return (
    <Fragment>
      <div
        className="bg-yellow-500 text-blue-presik px-6 !py-4 flex items-center justify-center space-x-3 rounded-md shadow-md hover:scale-95 transition-all duration-150 cursor-pointer mx-auto w-full right-0 bottom-0 col-span-2 max-w-fit my-10 text-xl"
        onClick={() => (showModal.value = true)}
      >
        <QrCodeIcon className="w-8" />
        <span>Scanea y agrega los cilindros</span>
      </div>

      {/* <div className="col-span-2 w-full block py-8">
        <h2 className="text-center text-xl font-medium">Listar productos</h2>
        {record && record.lines.size > 0 && (
          <div>
            {[...record.lines].map(([key, line]) => (
              <div key={key} className="p-2 border-b">
                <h3>{line.name}</h3>
                <span>Código: {line.code}</span>
                <span>Precio unitario: {line.unit_price}</span>
                <span>Cantidad: {line.quantity}</span>
                {line.product && (
                  <div>
                    <span>Producto ID: {line.product.id}</span>
                    <span>Nombre del producto: {line.product.name}</span>
                    <span>Referencia: {line.product.reference}</span>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div> */}

      <PureModal
        open={showModal.value}
        onClose={() => (showModal.value = false)}
        overflow="overflow-hidden"
      >
        <ContentModal />
      </PureModal>
    </Fragment>
  );
};

export default AddProduct;
